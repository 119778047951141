import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {Form, Formik, FormikHelpers} from "formik";
import {BaseMultiInput} from "@components/common/inputs/BaseMultiInput/BaseMultiInput";
import {DarkBlueRoundedButton} from "@components/common/buttons/DarkBlueRoundedButton/DarkBlueRoundedButton";
import {convertApiFormErrors, isApiFormResponse} from "@helpers/errors";
import {useTranslatedSchema} from "@helpers/hooks/useTranslatedSchema";
import {google2FAEnterCodeValidationSchema} from "@helpers/validationSchemas/google-2fa";
import {useVerifyGoogleTwoFactorAuthMutation} from "@features/two_factor_auth/google/api";

interface Values {
    code: string,
}

const initialValues: Values = {
    code: "",
}

export const TwoFactorAuthScreenForm: FunctionComponent = () => {
    const [verifyGoogleTwoFactorAuth] = useVerifyGoogleTwoFactorAuthMutation()

    const schema = useTranslatedSchema(google2FAEnterCodeValidationSchema)

    const onSubmit = async (values: any, {setErrors}: FormikHelpers<any>) => {
        try {
            await verifyGoogleTwoFactorAuth(values).unwrap()
        } catch (e) {
            if (isApiFormResponse(e)) {
                const errors = convertApiFormErrors(e)

                setErrors(errors.errors)
            }
        }
    }

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
            <Form className={styles.form}>
                <h2 className={styles.title}>Lorem ipsum dolor sit amet</h2>
                <div className={styles.fieldSet}>
                    <BaseMultiInput
                        name="code"
                        length={6}
                        type="number"
                        classNames={{
                            groupClassName: styles.group,
                            wrapperClassName: styles.wrapper,
                            inputClassName: styles.input,
                            errorsContainer: styles.errors,
                        }}/>
                </div>
                <div className={styles.submitButton}>
                    <DarkBlueRoundedButton type="submit">Submit</DarkBlueRoundedButton>
                </div>
            </Form>
        </Formik>
    )
}