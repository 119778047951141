import {FunctionComponent, useEffect, useState} from "react"

import styles from "./styles.module.css"
import {OverlayingPopup} from "@components/common/popups/OverlayingPopup/OverlayingPopup";
import CloseIcon from "@mui/icons-material/Close";
import {Form, Formik, FormikHelpers} from "formik";
import {Inspection} from "@appTypes/inspection";
import {useAdminAcceptInspectionRequestMutation} from "@features/inspection/api";
import {acceptInspectionRequestFormValidationSchema} from "@helpers/validationSchemas/admin/inspection";
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";
import {useNavigate} from "react-router-dom";
import {Tab, TabPanel, Tabs, TabsList} from "@mui/base";
import {GreenTab} from "@components/admin/tabs/GreenTab/GreenTab";
import {LightBox} from "@helpers/components/LightBox/LightBox";
import {CertificateConstructor} from "@components/admin/CertificateConstructor";
import {convertApiFormErrors, isApiFormResponse} from "@helpers/errors";
import {NonFieldErrors} from "@components/common/NonFieldErrors/NonFieldErrors";
import {UploadInspectionCertificate} from "@components/admin/UploadInspectionCertificate";
import {TransparentButton} from "@components/common/buttons/TransparentButton/TransparentButton";
import {log} from "@craco/craco/dist/lib/logger";

interface AcceptInspectionPopupProps {
    open: boolean,
    onClose: () => void,
    inspection: Inspection,
}

interface Values {
}

const initialValues: Values = {
}

export const AcceptInspectionPopup: FunctionComponent<AcceptInspectionPopupProps> = ({open, onClose, inspection}) => {
    const navigate = useNavigate()

    const [isValid, setValid] = useState<boolean>(false)

    const [acceptInspection, {isLoading}] = useAdminAcceptInspectionRequestMutation()

    const onSubmit = async (values: Values, {setSubmitting, setErrors}: FormikHelpers<Values>) => {
        setSubmitting(true)

        try {
            await acceptInspection({id: inspection.id}).unwrap()

            navigate(`/archive/${inspection.id}`, {replace: true})
            onClose()
        } catch (e) {
            if (isApiFormResponse(e)) {
                const errors = convertApiFormErrors(e)

                setErrors(errors.errors)
            }
        } finally {
            setSubmitting(false)
        }
    }

    const validate = () => {

        const errors: { [key: string]: string } = {}

        if (inspection.certificates.length < inspection.inspection_companies.length) {
            errors.non_field_errors = 'Загружены не все сертификаты'
        }

        setValid(!Object.keys(errors).length)
    }

    useEffect(() => {
        validate()
    }, [inspection.certificates.length]);

    return (
        <OverlayingPopup isOpened={open} onClose={onClose}>
            <div className={styles.contents}>
                <div className={styles.header}>
                    <h3>Certificates</h3>
                    <TransparentButton onClick={onClose}>
                        <CloseIcon className={styles.icon}/>
                    </TransparentButton>
                </div>
                <div>
                    <Tabs defaultValue={inspection.inspection_companies[0]?.id || 0}>
                        <TabsList className={styles.tabsList}>
                            {
                                inspection.inspection_companies.map((company, idx) => (
                                    <GreenTab key={idx} value={company.id} className={styles.tabSwitch}>
                                                    <span>
                                                        {company.name}
                                                    </span>
                                    </GreenTab>
                                ))
                            }
                        </TabsList>
                        {
                            inspection.inspection_companies.map((company, idx) => (
                                <TabPanel key={idx} value={company.id}>
                                    {
                                        !!Object.values(inspection.required_fields[company.id] || []).length ?
                                            <CertificateConstructor inspection={inspection} company={company}/>
                                            :
                                            <UploadInspectionCertificate inspection={inspection} company={company}/>
                                    }
                                </TabPanel>
                            ))
                        }
                    </Tabs>
                </div>
                <Formik onSubmit={onSubmit} initialValues={initialValues} validate={validate} validateOnChange>
                    {({
                          isSubmitting,
                      }) => (
                        <Form className={styles.form}>
                            <NonFieldErrors/>
                            <div className={styles.submitButton}>
                                <GreenRoundedButton type="submit"
                                                    disabled={isSubmitting || !isValid || isLoading}>Submit</GreenRoundedButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </OverlayingPopup>
    )
}