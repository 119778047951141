import {FunctionComponent} from "react";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {logout} from "@features/auth/slice";

import SignInIcon from '@mui/icons-material/Person';
import ContactUsIcon from '@mui/icons-material/Contactless';
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';

import styles from "./styles.module.css"
import { googleLogout } from "@react-oauth/google";
import {useTranslation} from "react-i18next";

export const GuestLinks: FunctionComponent = () => {
    const {t} = useTranslation()

    return (
        <>
            <Link to="sign_in" className={styles.link}><SignInIcon/> {t("user:Menu.links.SignIn")}</Link>
            <Link to="contact-us" className={styles.link}><ContactUsIcon/> {t("user:Menu.links.ContactUs")}</Link>
        </>
        )
}

export const UserLinks: FunctionComponent = () => {
    const dispatch = useDispatch()

    const {t} = useTranslation()

    const logoutMe = () => {
        dispatch(logout())

        googleLogout()
    }

    return (
        <>
            <Link to="dashboard" className={styles.link}><DashboardIcon/> {t("user:Menu.links.Dashboard")}</Link>
            <Link to="contact-us" className={styles.link}><ContactUsIcon/> {t("user:Menu.links.ContactUs")}</Link>
            <Link to="#" onClick={logoutMe} className={styles.link}><LogoutIcon/> {t("user:Menu.links.Logout")}</Link>
        </>
    )
}
