import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {Form, Formik, FormikHelpers} from "formik"
import {useLocation, useNavigate} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {getFormData, getFormValues, setFormData} from "@helpers/multiStepForm"
import {NEW_INSPECTION_FORM_DATA_KEY} from "@helpers/constants/inspection"
import {carInteriorFormValidationSchema} from "@helpers/validationSchemas/new-inspection"
import {InspectionPhoto, InspectionVideo} from "@appTypes/files"
import {WhiteCameraImageInput} from "@components/common/inputs/WhiteCameraImageInput/WhiteCameraImageInput"
import {HintInterceptor} from "@helpers/components/HintInterceptor/HintInterceptor"
import {WhiteCameraVideoInput} from "@components/common/inputs/WhiteCameraVideoInput/WhiteCameraVideoInput";

import InteriorDriverSideHint from "@assets/media/hints/InteriorDriverSideHint.jpg"
import DriverSeatAdjustmentHint from "@assets/media/hints/DriverSeatAdjustmentHint.jpg"
import InteriorPassengerSideHint from "@assets/media/hints/InteriorPassengerSideHint.jpg"
import PassengerSeatAdjustmentHint from "@assets/media/hints/PassengerSeatAdjustmentHint.jpg"
import InteriorBackseatHint from "@assets/media/hints/InteriorBackseatHint.jpg"
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";

const HornHint = require("@assets/media/hints/HornHint.MOV")

interface CarInteriorProps {
    title?: string,
    description?: string,
}

interface Values {
    horn: InspectionVideo | null,
    interior_driver_side: InspectionPhoto | null,
    driver_seat_adjustment: InspectionPhoto | null,
    interior_passenger_side: InspectionPhoto | null,
    passenger_seat_adjustment: InspectionPhoto | null,
    interior_backseat: InspectionPhoto | null,
}

export const CarInterior: FunctionComponent<CarInteriorProps> = ({title, description}) => {
    const initialValues: Values = {
        horn: null,
        interior_driver_side: null,
        driver_seat_adjustment: null,
        interior_passenger_side: null,
        passenger_seat_adjustment: null,
        interior_backseat: null,
        ...getFormValues(NEW_INSPECTION_FORM_DATA_KEY, 'horn', 'interior_driver_side', 'driver_seat_adjustment', 'interior_passenger_side', 'passenger_seat_adjustment', 'interior_backseat'),
    }

    const navigate = useNavigate()
    const location = useLocation()

    const {t} = useTranslation()

    const onSubmit = async (values: Values, {setSubmitting, setErrors}: FormikHelpers<Values>) => {
        setFormData(NEW_INSPECTION_FORM_DATA_KEY, {
            ...getFormData(NEW_INSPECTION_FORM_DATA_KEY),
            ...values
        })

        navigate(location, {
            state: {
                activeStep: 3
            }
        })

        setSubmitting(false)
    }

    return (
        <div className={styles.step}>
            <Formik initialValues={initialValues} onSubmit={onSubmit}
                    validationSchema={carInteriorFormValidationSchema}>
                {({
                      isSubmitting,
                      errors,
                  }) => (
                    <Form className={styles.form}>
                        <HintInterceptor
                            hintKey="horn"
                            hintTitle={t("user:NewInspection.CarInterior.hints.Horn.title")}
                            hintDescription={t("user:NewInspection.CarInterior.hints.Horn.description")}
                            hintFileSource={HornHint}
                            hintFileType="video">
                            <WhiteCameraVideoInput name="horn"
                                                   label={t("user:NewInspection.CarInterior.HornVideoInput.label")}/>
                        </HintInterceptor>
                        <HintInterceptor
                            hintKey="interior_driver_side"
                            hintTitle={t("user:NewInspection.CarInterior.hints.InteriorDriverSide.title")}
                            hintDescription={t("user:NewInspection.CarInterior.hints.InteriorDriverSide.description")}
                            hintFileSource={InteriorDriverSideHint}
                            hintFileType="image">
                            <WhiteCameraImageInput name="interior_driver_side"
                                                   label={t("user:NewInspection.CarInterior.InteriorDriverSideImageInput.label")}/>
                        </HintInterceptor>
                        <HintInterceptor
                            hintKey="driver_seat_adjustment"
                            hintTitle={t("user:NewInspection.CarInterior.hints.DriverSeatAdjustment.title")}
                            hintDescription={t("user:NewInspection.CarInterior.hints.DriverSeatAdjustment.description")}
                            hintFileSource={DriverSeatAdjustmentHint}
                            hintFileType="image">
                            <WhiteCameraImageInput name="driver_seat_adjustment"
                                                   label={t("user:NewInspection.CarInterior.DriverSeatAdjustmentImageInput.label")}/>
                        </HintInterceptor>
                        <HintInterceptor
                            hintKey="interior_passenger_side"
                            hintTitle={t("user:NewInspection.CarInterior.hints.InteriorPassengerSide.title")}
                            hintDescription={t("user:NewInspection.CarInterior.hints.InteriorPassengerSide.description")}
                            hintFileSource={InteriorPassengerSideHint}
                            hintFileType="image">
                            <WhiteCameraImageInput name="interior_passenger_side"
                                                   label={t("user:NewInspection.CarInterior.InteriorPassengerSideImageInput.label")}/>
                        </HintInterceptor>
                        <HintInterceptor
                            hintKey="passenger_seat_adjustment"
                            hintTitle={t("user:NewInspection.CarInterior.hints.PassengerSeatAdjustment.title")}
                            hintDescription={t("user:NewInspection.CarInterior.hints.PassengerSeatAdjustment.description")}
                            hintFileSource={PassengerSeatAdjustmentHint}
                            hintFileType="image">
                            <WhiteCameraImageInput name="passenger_seat_adjustment"
                                                   label={t("user:NewInspection.CarInterior.PassengerSeatAdjustmentImageInput.label")}/>
                        </HintInterceptor>
                        <HintInterceptor
                            hintKey="interior_backseat"
                            hintTitle={t("user:NewInspection.CarInterior.hints.InteriorBackseat.title")}
                            hintDescription={t("user:NewInspection.CarInterior.hints.InteriorBackseat.description")}
                            hintFileSource={InteriorBackseatHint}
                            hintFileType="image">
                            <WhiteCameraImageInput name="interior_backseat"
                                                   label={t("user:NewInspection.CarInterior.InteriorBackseatImageInput.label")}/>
                        </HintInterceptor>
                        <div className={styles.submitButton}>
                            <GreenRoundedButton type="submit" disabled={isSubmitting || !!Object.values(errors).length}>
                                {t("common:Inspection.NextStepButton")}
                            </GreenRoundedButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}