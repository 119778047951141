import {BrowserRouter} from "react-router-dom"
import {GuestRoutes} from "@helpers/routes/guest"
import {AdminRoutes} from "@helpers/routes/admin"
import {UserRoutes} from "@helpers/routes/user"
import {InspectorRoutes} from "@helpers/routes/inspector"
import {useAppSelector} from "@features/redux/store"
import {getMe} from "@features/auth/slice"
import {useMemo} from "react"
import {TwoFactorAuthScreen} from "@components/common/screens/TwoFactorAuthScreen/TwoFactorAuthScreen"
import {CookiesAlert} from "@components/CookiesAlert/CookiesAlert";

export const App = () => {
    const user = useAppSelector(getMe)

    const routes = useMemo(() => (
        <TwoFactorAuthScreen>
            <AdminRoutes/>
            <InspectorRoutes/>
            <UserRoutes/>
            <GuestRoutes/>
            <CookiesAlert/>
        </TwoFactorAuthScreen>
    ), [user])

    return (
        <BrowserRouter>
            {routes}
        </BrowserRouter>
    )
}
