import {api} from "@features/redux/api";

import {MainPageVideo} from "@appTypes/mainPageVideo";

const coreApi = api.injectEndpoints({
    endpoints: builder => ({
        getMainPageVideo: builder.query<MainPageVideo[], void>({
            query: () => ({
                url: 'core/video',
                method: "GET",
            })
        })
    })
})

export const {
    useGetMainPageVideoQuery,
} = coreApi;
