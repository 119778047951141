import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {Form, Formik, FormikHelpers} from "formik";
import {updateUserPasswordValidationSchema} from "@helpers/validationSchemas/admin/update-user";
import {WhiteInput} from "@components/common/inputs/WhiteInput/WhiteInput";
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";
import {OverlayingPopup, OverlayingPopupProps} from "@components/common/popups/OverlayingPopup/OverlayingPopup";
import {string} from "yup";
import CloseIcon from "@mui/icons-material/Close";
import {convertApiFormErrors, isApiFormResponse} from "@helpers/errors";
import {User} from "@appTypes/user";
import {useUpdateUserPasswordMutation} from "@features/users/api";
import {useTranslatedSchema} from "@helpers/hooks/useTranslatedSchema";

interface UpdateUserPasswordPopupProps extends Omit<OverlayingPopupProps, "children"> {
    user: User,
}

interface UpdatePasswordFormValues {
    new_password: string,
    new_password_confirmation: string,
}

const updatePasswordFormInitialValues: UpdatePasswordFormValues = {
    new_password: "",
    new_password_confirmation: "",
}

export const UpdateUserPasswordPopup: FunctionComponent<UpdateUserPasswordPopupProps> = ({onClose, isOpened, user}) => {
    const [updateUserPassword] = useUpdateUserPasswordMutation()

    const schema = useTranslatedSchema(updateUserPasswordValidationSchema)

    const updatePasswordHandler = async (values: UpdatePasswordFormValues, {setSubmitting, setErrors}: FormikHelpers<UpdatePasswordFormValues>) => {
        setSubmitting(true)
        try {
            await updateUserPassword({id: user.id, ...values}).unwrap()

            setSubmitting(false)

            onClose()
        }
        catch (e) {
            setSubmitting(false)

            if (isApiFormResponse(e)) {
                const errors = convertApiFormErrors(e)

                setErrors(errors.errors)
            }
        }
    }

    return (
        <OverlayingPopup onClose={onClose} isOpened={isOpened}>
            <div className={styles.contents}>
                <div className={styles.header}>
                    <h3>Update user password</h3>
                    <CloseIcon onClick={onClose} className={styles.icon}/>
                </div>
                <Formik initialValues={updatePasswordFormInitialValues} onSubmit={updatePasswordHandler}
                        validationSchema={schema}>
                    {({dirty, isSubmitting, isValid}) => (
                        <Form className={styles.form}>
                                <WhiteInput name="new_password" label="New password" type="password"/>
                                <WhiteInput name="new_password_confirmation" label="Confirm new password"
                                            type="password"/>
                                <div className={styles.submitButton}>
                                    {dirty ? <GreenRoundedButton type="submit" disabled={isSubmitting || !isValid}>Reset</GreenRoundedButton> : null}
                                </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </OverlayingPopup>
    )
}