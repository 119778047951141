import {Navigate, Route, Routes} from "react-router-dom";
import {Dashboard as AdminDashboard} from "@pages/admin/Dashboard/Dashboard";
import {AdminLayout} from "@layouts/AdminLayout/AdminLayout";
import {Requests} from "@pages/admin/Requests/Requests";
import {Users} from "@pages/admin/Users/Users";
import {Archive} from "@pages/admin/Archive/Archive";
import {PermittedComponent} from "@helpers/components/PermittedComponent/PermittedComponent";
import {ActiveInspectionDetails} from "@pages/admin/ActiveInspectionDetails/ActiveInspectionDetails";
import {ArchiveInspectionDetails} from "@pages/admin/ArchiveInspectionDetails/ArchiveInspectionDetails";
import {UserDetails} from "@pages/admin/UserDetails/UserDetails";
import {Proposals} from "@pages/admin/Proposals/Proposals";
import {ProposalDetails} from "@pages/admin/ProposalDetails/ProposalDetails";
import {Settings} from "@pages/admin/Settings/Settings";
import {CreateUser} from "@pages/admin/CreateUser/CreateUser";

export const AdminRoutes = () => (
    <PermittedComponent allowedRoles={["super_admin", "admin"]}>
        <Routes>
            <Route element={<AdminLayout/>}>
                <Route index element={<AdminDashboard/>}/>
                <Route path="requests" element={<Requests/>}/>
                <Route path="users" element={<Users/>}/>
                <Route path="users/create" element={<CreateUser/>}/>
                <Route path="archive" element={<Archive/>}/>
                <Route path="proposals" element={<Proposals/>}/>
                <Route path="proposals/:id" element={<ProposalDetails/>}/>
                <Route path="requests/:id" element={<ActiveInspectionDetails/>}/>
                <Route path="archive/:id" element={<ArchiveInspectionDetails/>}/>
                <Route path="users/:id" element={<UserDetails/>}/>
                <Route path="settings" element={<Settings/>}/>
            </Route>
            <Route element={<Navigate to="/" replace/>} path="/*"/>
        </Routes>
    </PermittedComponent>
)