import {FunctionComponent, useState} from "react"

import styles from "./styles.module.css"
import {Inspection} from "@appTypes/inspection";
import {Download, ExpandLess, ExpandMore} from "@mui/icons-material";
import {InspectionProgressLabel} from "@components/common/InspectionProgressLabel/InspectionProgressLabel";
import { Link } from "react-router-dom";
import {useGetInspectionPaymentQuery} from "@features/payments/api";
import {Skeleton} from "@mui/material";
import {useTranslation} from "react-i18next";
import {getAmericanFormattedDateTimeString} from "@helpers/date";

interface CardProps {
    inspection: Inspection
}

export const Card: FunctionComponent<CardProps> = ({inspection}) => {
    const {
        id,
        license_plate_number,
        date_created,
        status,
        certificates,
    } = inspection

    const {t} = useTranslation()

    const [isExpanded, setExpanded] = useState<boolean>(false)

    const {data: inspectionPayment,} = useGetInspectionPaymentQuery({id},
        {
            skip: status !== "unpaid"
        })

    return (
        <div className={styles.card}>
            <div className={styles.generalInfo}>
                <div className={styles.wrapper}>
                    <div>
                        <p className={styles.label}>{t("user:Dashboard.InspectionsList.Card.license_plate_number")}</p>
                        <b className={styles.value}>{license_plate_number}</b>
                    </div>
                </div>
                <div className={styles.wrapper}>
                    <div>
                        <p className={styles.label}>{t("user:Dashboard.InspectionsList.Card.date_created")}</p>
                        <b className={styles.value}>{getAmericanFormattedDateTimeString(date_created)}</b>
                    </div>
                </div>
            </div>
            <div className={styles.statusInfo} onClick={() => setExpanded(prevState => !prevState)}>
                <p>{t("user:Dashboard.InspectionsList.Card.status_label")}</p>
                {isExpanded ? <ExpandLess/> : <ExpandMore/>}
                <InspectionProgressLabel status={status}/>
            </div>
            <div className={`${styles.actions} ${isExpanded ? styles.open : ""}`}>
                {!!certificates.length && status === "reviewed" &&
                    <>
                        {inspection.certificates.map((certificate, idx) => (
                            <Link to={certificate.file} key={idx}>
                                <Download/> {certificate.company.name} {t("user:Dashboard.InspectionsList.Card.CertificateDownloadLabel")}
                            </Link>
                        ))}
                    </>
                }
                {
                    status === "rejected" &&
                    <Link to={`/re-inspect/${id}`}>{t("user:Dashboard.InspectionsList.Card.ReInspectButton")}</Link>
                }
                {
                    status === "unpaid" && inspectionPayment?.url &&
                    <a href={inspectionPayment?.url}>{t("user:Dashboard.InspectionsList.Card.PaymentLinkButtonLabel")}</a>
                }
                <Link to={`/details/${id}`}>
                    {t("user:Dashboard.InspectionsList.Card.CarDetailsButtonLabel")}
                </Link>
            </div>
        </div>
    )
}

export const CardPlaceholder: FunctionComponent = () => {
    const {t} = useTranslation()

    return (
        <div className={styles.card}>
            <div className={styles.generalInfo}>
                <div className={styles.wrapper}>
                    <div>
                        <Skeleton>
                            <p className={styles.label}>{t("user:Dashboard.InspectionsList.Card.license_plate_number")}</p>
                        </Skeleton>
                        <Skeleton>
                            <b className={styles.value}>123456789</b>
                        </Skeleton>
                    </div>
                </div>
                <div className={styles.wrapper}>
                    <div>
                        <Skeleton>
                            <p className={styles.label}>{t("user:Dashboard.InspectionsList.Card.date_created")}</p>
                        </Skeleton>
                        <Skeleton>
                            <b className={styles.value}>01.01.01 23:50</b>
                        </Skeleton>
                    </div>
                </div>
            </div>
            <div className={styles.statusInfo}>
                <Skeleton>
                    <p>{t("user:Dashboard.InspectionsList.Card.status_label")}</p>
                </Skeleton>
                <Skeleton>
                    <ExpandMore/>
                </Skeleton>
                <Skeleton>
                    <InspectionProgressLabel status="pending"/>
                </Skeleton>
            </div>
        </div>
    )
}
