import {FunctionComponent} from "react"
import {OverlayingPopup} from "@components/common/popups/OverlayingPopup/OverlayingPopup";
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";
import {WhiteInput} from "@components/common/inputs/WhiteInput/WhiteInput";
import {Form, Formik, FormikErrors, FormikHelpers} from "formik";
import {convertApiFormErrors, isApiFormResponse} from "@helpers/errors";
import {massMailingFormValidationSchema} from "@helpers/validationSchemas/admin/mass-mailing";
import {useMassMailingMutation} from "@features/mailing/api";

import CloseIcon from "@mui/icons-material/Close";
import styles from "./styles.module.css"

interface RejectInspectionPopupProps {
    open: boolean,
    onClose: () => void,
}

interface Values {
    registered_already_days: number;
    last_inspection_days: number;
}

const initialValues: Values = {
    registered_already_days: 0,
    last_inspection_days: 0,
}

const MassMailingPopup: FunctionComponent<RejectInspectionPopupProps> = ({open, onClose}) => {
    const [massMailing, {isLoading}] = useMassMailingMutation();

    const onSubmit = async (values: Values, {setSubmitting, setErrors}: FormikHelpers<Values>) => {
        try {
            await massMailing({
                registered_already_days: values.registered_already_days,
                last_inspection_days: values.last_inspection_days,
            }).unwrap();

            setSubmitting(false);
            onClose();
        } catch (e) {
            setSubmitting(false)
            if (isApiFormResponse(e)) {
                const errors = convertApiFormErrors(e);
                setErrors(errors.errors as FormikErrors<Values>);
            }
        }
    }

    return (
        <OverlayingPopup isOpened={open} onClose={onClose}>
            <div className={styles.contents}>
                <div className={styles.header}>
                    <h3>Mass Mailing</h3>
                    <CloseIcon onClick={onClose} className={styles.icon}/>
                </div>
                <Formik onSubmit={onSubmit} initialValues={initialValues}
                        validationSchema={massMailingFormValidationSchema}>
                    {({
                          isSubmitting,
                          errors,
                      }) => (
                        <Form className={styles.form}>
                            <WhiteInput name="registered_already_days" label="Registered already days"
                                        placeholder="Registered already days" type="number"/>
                            <WhiteInput name="last_inspection_days" label="Last inspection days"
                                        placeholder="Last inspection days" type="number"/>
                            <div className={styles.submitButton}>
                                <GreenRoundedButton type="submit"
                                                    disabled={isSubmitting || !!Object.values(errors).length || isLoading}>Submit</GreenRoundedButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </OverlayingPopup>
    );
}

export {
    MassMailingPopup,
}
