import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {useTranslation} from "react-i18next";

interface InspectionFormDescriptionProps {
    description?: string
}

export const InspectionFormDescription: FunctionComponent<InspectionFormDescriptionProps> = ({description}) => {
    const {t} = useTranslation()

    if (!description) {
        return null
    }

    return (
        <p className={styles.description}>
            {t(description)}
        </p>
    )
}
