import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {SyncLoader} from "react-spinners";

export const LoadingScreen: FunctionComponent = () => {
    return (
        <div className={styles.screen}>
            <SyncLoader color="#09a8a6"/>
        </div>
    )
}
