import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {OverlayingPopup} from "@components/common/popups/OverlayingPopup/OverlayingPopup";
import CloseIcon from "@mui/icons-material/Close";
import {Form, Formik, FormikErrors, FormikHelpers} from "formik";
import {Inspection} from "@appTypes/inspection";
import {useAdminRejectInspectionRequestMutation} from "@features/inspection/api";
import {rejectInspectionRequestFormValidationSchema} from "@helpers/validationSchemas/admin/inspection";
import {useNavigate} from "react-router-dom";
import {WhiteTextArea} from "@components/common/inputs/WhiteTextArea/WhiteTextArea";
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";
import {convertApiFormErrors, isApiFormResponse} from "@helpers/errors";
import {WhiteSelectInput} from "@components/common/inputs/WhiteSelectInput/WhiteSelectInput";

interface RejectInspectionPopupProps {
    open: boolean,
    onClose: () => void,
    inspection: Inspection,
}

interface Values {
    incorrect_fields: string[],
    rejection_reason: string,
}

const initialValues: Values = {
    incorrect_fields: [""],
    rejection_reason: "",
}

export const RejectInspectionPopup: FunctionComponent<RejectInspectionPopupProps> = ({open, onClose, inspection}) => {
    const navigate = useNavigate()
    const [rejectInspection, {isLoading}] = useAdminRejectInspectionRequestMutation()

    const onSubmit = async (values: Values, {setSubmitting, setErrors}: FormikHelpers<Values>) => {
        try {
            await rejectInspection({
                id: inspection.id,
                incorrect_fields: String(values.incorrect_fields).toString(),
                rejection_reason: values.rejection_reason
            }).unwrap()

            setSubmitting(false)
            navigate(`/archive/${inspection.id}`, {replace: true})
            onClose()
        } catch (e) {
            setSubmitting(false)
            if (isApiFormResponse(e)) {
                const errors = convertApiFormErrors(e)
                setErrors(errors.errors as FormikErrors<Values>)
            }
        }
    }

    return (
        <OverlayingPopup isOpened={open} onClose={onClose}>
            <div className={styles.contents}>
                <div className={styles.header}>
                    <h3>Files</h3>
                    <CloseIcon onClick={onClose} className={styles.icon}/>
                </div>
                <Formik onSubmit={onSubmit} initialValues={initialValues}
                        validationSchema={rejectInspectionRequestFormValidationSchema}>
                    {({
                          isSubmitting,
                          errors,
                      }) => (
                        <Form className={styles.form}>
                            <WhiteTextArea name="rejection_reason" label="Rejection Reason" placeholder="Type here..."/>
                            <WhiteSelectInput name="incorrect_fields" label="Incorrect Fields" noValueOption multiple>
                                <option value="license_plate_number">License Plate Number</option>
                                <option value="applicant_drivers_license">Applicant driver's license</option>
                                <option value="registration_card">Registration Card</option>
                                <option value="odometer">Odometer</option>
                                <option value="horn">Horn</option>
                                <option value="interior_driver_side">Interior Driver Side</option>
                                <option value="driver_seat_adjustment">Driver Seat Adjustment</option>
                                <option value="interior_passenger_side">Interior Passenger Side</option>
                                <option value="passenger_seat_adjustment">Passenger Seat Adjustment</option>
                                <option value="interior_backseat">Interior Backseat</option>
                                <option value="exterior_left">Exterior Left</option>
                                <option value="exterior_right">Exterior Right</option>
                                <option value="exterior_front">Exterior Front</option>
                                <option value="exterior_rear">Exterior Rear</option>
                                <option value="left_front_tire">Left Front Tire</option>
                                <option value="right_front_tire">Right Front Tire</option>
                                <option value="left_rear_tire">Left Rear tire</option>
                                <option value="right_rear_tire">Right Rear Tire</option>
                            </WhiteSelectInput>
                            <div className={styles.submitButton}>
                                <GreenRoundedButton type="submit"
                                                    disabled={isSubmitting || !!Object.values(errors).length || isLoading}>Submit</GreenRoundedButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </OverlayingPopup>
    )
}
