import {FunctionComponent, useCallback, useState} from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { InspectionsList } from "@components/user/InspectionsList/InspectionsList";
import { Filters as InspectionFilters } from "@components/user/InspectionsList/Filters/Filters"
import { InspectionsListFiltersPopup } from "@components/user/popups/InspectionsListFiltersPopup/InspectionsListFiltersPopup";
import { GreenRoundedButton } from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";
import { CardPlaceholder } from "@components/user/InspectionsList/Card/Card";
import { InspectionsListFilters, useUserInspectionsListQuery } from "@features/inspection/api";

import EmptyUserInspectionsListImage from "@assets/media/images/EmptyUserInspectionsList.png";
import styles from "./styles.module.css";

export const Dashboard: FunctionComponent = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [filters, setFilters] = useState<InspectionsListFilters>({
        ordering: "-date_created",
        status__in: ["submitted", "rejected", "reviewed", "pending", "unpaid"]
    });

    const [filtersModalOpened, setFiltersModalOpened] = useState<boolean>(false);

    const {data: inspections, isLoading} = useUserInspectionsListQuery(filters, {
        refetchOnMountOrArgChange: true,
    });

    const startInspection = useCallback(() => {
        navigate("/new_inspection");
    }, []);

    return (
        <div className={styles.page}>
            <h3 className={styles.title}>
                {t("user:Dashboard.heading")}
            </h3>
            {
                isLoading ?
                    Array(5).fill(null).map(() => (
                        <CardPlaceholder/>
                    ))
                    :
                    inspections?.length ?
                        <>
                            <div className={styles.filtersContainer}>
                                <InspectionFilters openFiltersModal={() => setFiltersModalOpened(true)}/>
                            </div>
                            <div className={styles.inspections}>
                                <InspectionsList inspections={inspections || []}/>
                            </div>
                        </>
                        :
                        <div className={styles.empty}>
                            <img src={EmptyUserInspectionsListImage} alt="EmptyUserInspectionsListImage"/>
                            <p className={styles.description}>
                                {t("user:Dashboard.InspectionsList.Empty.description")}
                            </p>
                            <p className={styles.hint}>
                                {t("user:Dashboard.InspectionsList.Empty.hint")}
                            </p>
                            <GreenRoundedButton
                                onClick={startInspection}>{t("user:Dashboard.InspectionsList.Empty.ActionButton")}</GreenRoundedButton>
                        </div>
            }
            <InspectionsListFiltersPopup isOpened={filtersModalOpened} onClose={() => setFiltersModalOpened(false)}
                                         filters={filters} setFilters={setFilters}/>
        </div>
    )
}