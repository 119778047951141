import {FunctionComponent, ChangeEvent} from "react"

import {useField} from "formik";
import {FieldHookConfig} from "formik/dist/Field";
import {GenericFieldHTMLAttributes} from "formik/dist/types";
import { InputError } from "@components/common/InputError/InputError";
import styles from "./styles.module.css"

interface WhiteInputProps  {
    label: string,
    className?: string,
}

export const WhiteInput: FunctionComponent<WhiteInputProps & GenericFieldHTMLAttributes & FieldHookConfig<string>> = ({label, className = "", ...fieldProps}) => {
    const [field, meta, helpers] = useField(fieldProps)

    const innerOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        field.onChange(event)

        if (fieldProps.type === "tel") {
            if (!event.currentTarget.value.startsWith("+")) {
                if (!event.currentTarget.value.startsWith("+1")) {
                    helpers.setValue(`+1${event.currentTarget.value}`)
                }
                else {
                    helpers.setValue(`+1${event.currentTarget.value.replace('+', '')}`)
                }
            }
            else if (!event.currentTarget.value.startsWith("+1")) {
                helpers.setValue(`+1${event.currentTarget.value.replace('+', '')}`)
            }
        }
    }

    return (
        <div className={styles.container}>
            <label htmlFor={field.name} className={styles.label}>{label}</label>
            <>
                <InputError error={meta.error} touched={meta.touched}/>
                <input className={styles.input} placeholder={fieldProps.placeholder} {...field} onChange={innerOnChange} type={fieldProps.type} disabled={fieldProps.disabled}/>
            </>
        </div>
    );
}
