import {api} from "@features/redux/api";
import {AuthToken, User} from "@appTypes/user";

type SignOnWithGoogleQueryResult = {
    context: "sign_up",
    user: Pick<User, "email" | "first_name" | "last_name" | "phone_number">,
} | {
    context: "sign_in",
    user: User,
    token: AuthToken,
}
type SignOnWithGoogleQueryArgs = {
    access_token?: string,
}

type FinishSignUpWithGoogleQueryResult = {
    user: User,
    token: AuthToken,
}
type FinishSignUpWithGoogleQueryArgs = {
    email: string,
    first_name: string,
    last_name: string,
    phone_number: string,
}


export const googleOauthApi = api.injectEndpoints({
    endpoints: builder => ({
        signOnWithGoogle: builder.mutation<SignOnWithGoogleQueryResult, SignOnWithGoogleQueryArgs>({
            query: ({...args}) => ({
                url: "oauth/google/sign_on",
                method: "POST",
                body: args,
            }),
        }),
        finishSignUpWithGoogle: builder.mutation<FinishSignUpWithGoogleQueryResult, FinishSignUpWithGoogleQueryArgs>({
            query: ({...args}) => ({
                url: "oauth/google/sign_on/finish",
                method: "POST",
                body: args,
            }),
        }),
    })
})

export const {
    useSignOnWithGoogleMutation,
    useFinishSignUpWithGoogleMutation,
} = googleOauthApi
