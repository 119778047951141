import {FunctionComponent} from "react"

import AccessAnywhereIcon from "@assets/icons/home/AccessAnywhereIcon.svg"
import AffordablePricesIcon from "@assets/icons/home/AffordablePricesIcon.svg"
import RidiculouslySimpleIcon from "@assets/icons/home/RidiculouslySimpleIcon.svg"
import CompilanceIcon from "@assets/icons/home/CompilanceIcon.svg"
import AutomationIcon from "@assets/icons/home/AutomationIcon.svg"
import TransparencyIcon from "@assets/icons/home/TransparencyIcon.svg"

import styles from "./styles.module.css"
import {Image} from "@components/common/images/Image/Image";
import {useTranslation} from "react-i18next";

export const AboutUsSection: FunctionComponent = () => {
    const {t} = useTranslation()

    return (
        <div className={styles.section}>
            <h2 className={styles.title}>
                {t("user:Home.AboutUsSection.heading")}
            </h2>
            <div className={styles.content}>
                <div className={styles.card}>
                    <Image src={AccessAnywhereIcon} alt="AccessAnywhereIcon" skeletonWidth={45} skeletonHeight={65}/>
                    <h3 className={styles.heading}>{t("user:Home.AboutUsSection.cards.AccessAnywhere.heading")}</h3>
                    <p className={styles.description}>{t("user:Home.AboutUsSection.cards.AccessAnywhere.description")}</p>
                </div>
                <div className={styles.card}>
                    <Image src={AffordablePricesIcon} alt="AffordablePricesIcon" skeletonWidth={83} skeletonHeight={65}/>
                    <h3 className={styles.heading}>{t("user:Home.AboutUsSection.cards.AffordablePrices.heading")}</h3>
                    <p className={styles.description}>{t("user:Home.AboutUsSection.cards.AffordablePrices.description")}</p>
                </div>
                <div className={styles.card}>
                    <Image src={RidiculouslySimpleIcon} alt="RidiculouslySimpleIcon" skeletonWidth={59} skeletonHeight={65}/>
                    <h3 className={styles.heading}>{t("user:Home.AboutUsSection.cards.RidiculouslySimple.heading")}</h3>
                    <p className={styles.description}>{t("user:Home.AboutUsSection.cards.RidiculouslySimple.description")}</p>
                </div>
                <div className={styles.card}>
                    <Image src={CompilanceIcon} alt="CompilanceIcon" skeletonWidth={50} skeletonHeight={65}/>
                    <h3 className={styles.heading}>{t("user:Home.AboutUsSection.cards.Compliance.heading")}</h3>
                    <p className={styles.description}>{t("user:Home.AboutUsSection.cards.Compliance.description")}</p>
                </div>
                <div className={styles.card}>
                    <Image src={AutomationIcon} alt="AutomationIcon" skeletonWidth={63} skeletonHeight={65}/>
                    <h3 className={styles.heading}>{t("user:Home.AboutUsSection.cards.Automation.heading")}</h3>
                    <p className={styles.description}>{t("user:Home.AboutUsSection.cards.Automation.description")}</p>
                </div>
                <div className={styles.card}>
                    <Image src={TransparencyIcon} alt="TransparencyIcon" skeletonWidth={54} skeletonHeight={65}/>
                    <h3 className={styles.heading}>{t("user:Home.AboutUsSection.cards.Transparency.heading")}</h3>
                    <p className={styles.description}>{t("user:Home.AboutUsSection.cards.Transparency.description")}</p>
                </div>
            </div>
        </div>
    )
}
