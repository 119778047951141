import {ChangeEvent, FunctionComponent, MouseEvent, useId} from "react"

import {useField} from "formik";
import {useTranslation} from "react-i18next"

import styles from "./styles.module.css"
import {InputError} from "@components/common/InputError/InputError";
import {useUploadProgress} from "@helpers/hooks/useUploadProgress";
import {FileUpload as FileUploadIcon} from "@mui/icons-material";
import {Company, Inspection} from "@appTypes/inspection";
import {userInspectionApi} from "@features/inspection/api";
import {useAppDispatch} from "@features/redux/store";
import {InspectionCertificate} from "@appTypes/files";

interface InspectionCertificateInputProps {
    name: string,
    label: string,
    inspection: Inspection,
    company: Company,
    document_format?: string
}

export const InspectionCertificateInput: FunctionComponent<InspectionCertificateInputProps> = ({
                                                                                                   name,
                                                                                                   label,
                                                                                                   inspection,
                                                                                                   company,
                                                                                                   document_format = "*",
                                                                                               }) => {
    const fieldId = useId()

    const {t} = useTranslation()

    const {loadingProgress, isLoading, upload} = useUploadProgress()

    const [field, meta, helpers] = useField({name: name})

    const dispatch = useAppDispatch()

    const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }

        const file = event.target.files[0]

        const formData = new FormData()
        formData.append('file', file)
        formData.append('inspection', String(inspection.id).toString())
        formData.append('company', String(company.id).toString())

        upload(new URL('/api/v1/inspections/certificate/load/', process.env.REACT_APP_BACKEND_API_URL).toString(), formData)
            .then(response => {
                helpers.setValue((response.data.certificates as InspectionCertificate[]).find((certificate) => certificate.company.id === company.id))
                dispatch(userInspectionApi.util.invalidateTags([{type: 'Inspection', id: response.data.id}]))
                dispatch(userInspectionApi.util.upsertQueryData("adminActiveInspectionDetails", response.data.id, response.data))
            })
    }

    return (
        <div className={styles.container}>
            <div className={styles.description}>
                <span className={styles.title}>
                    {label}
                </span>
                <span className={styles.media_type}>
                    &nbsp;{t("common:MediaTypes.Certificate")}
                </span>
            </div>
            <div className={styles.errors}>
                {!isLoading && <InputError error={meta.error}/>}
            </div>
            <input {...field} id={fieldId} value="" type="file" onChange={handleUpload} accept={`application/pdf`}/>
            <label htmlFor={fieldId} className={styles.inputBox}>
                {
                    field.value?.file && !isLoading ?
                        <div className={styles.file}>
                            <FileUploadIcon/>
                            <span>{field.value.file.split('/').slice(-1)}</span>
                        </div>
                        :
                        isLoading ?
                            <div>{loadingProgress}%</div>
                            :
                            <div className={styles.placeholder}>
                                <FileUploadIcon/>
                                <p>
                                    Upload File
                                </p>
                            </div>
                }
            </label>
        </div>
    )
}
