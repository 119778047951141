import {FunctionComponent, useEffect, useRef} from "react"

import styles from "./styles.module.css"
import {useNavigate, useParams} from "react-router-dom";
import {useGetInspectionPaymentQuery} from "@features/payments/api";

import SuccessIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/HighlightOff';
import WaitIcon from '@mui/icons-material/HourglassTop';
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";
import {useTranslation} from "react-i18next";

export const InspectionPaymentStatus: FunctionComponent = () => {
    const {id} = useParams<string>()
    const navigate = useNavigate()

    const {t} = useTranslation()

    const statusTimeoutRef = useRef<NodeJS.Timeout>()

    const {data: payment, isLoading, refetch} = useGetInspectionPaymentQuery({id: parseInt(id!)}, {
        refetchOnMountOrArgChange: true,
    })

    useEffect(() => {
        statusTimeoutRef.current = setInterval(() => {
            refetch()
        }, 5000)
    }, [])

    useEffect(() => {
        if (payment?.status !== "pending") {
            clearInterval(statusTimeoutRef.current)
        }
    }, [payment?.status])

    const goToDashboard = () => {
        navigate("/dashboard")
    }

    const retryPayment = () => {
        if (payment?.url) {
            window.location.href = payment?.url
        }
    }

    if (isLoading) {
        return null
    }

    return (
        <div className={styles.page}>
            <div className={styles.container}>
                <div className={styles.heading}>
                    <h1>
                        {
                            payment?.status === "pending" &&
                            t("user:InspectionPaymentStatus.pending.title")
                        }
                        {
                            payment?.status === "success" &&
                            t("user:InspectionPaymentStatus.success.title")
                        }
                        {
                            payment?.status === "expired" &&
                            t("user:InspectionPaymentStatus.expired.title")
                        }
                    </h1>
                </div>
                <div className={styles.notification}>
                    <div className={`${styles.icon} ${styles[payment?.status || ""]}`}>
                        {
                            payment?.status === "pending" &&
                            <WaitIcon/>
                        }
                        {
                            payment?.status === "success" &&
                            <SuccessIcon/>
                        }
                        {
                            payment?.status === "expired" &&
                            <ErrorIcon/>
                        }
                    </div>
                    <h4>
                        {
                            payment?.status === "pending" &&

                            t("user:InspectionPaymentStatus.pending.description")
                        }
                        {
                            payment?.status === "success" &&
                            t("user:InspectionPaymentStatus.success.description")
                        }
                        {
                            payment?.status === "expired" &&
                            t("user:InspectionPaymentStatus.expired.description")
                        }
                    </h4>
                    <div className={styles.description}>
                        <p>
                            {t("user:InspectionPaymentStatus.description")}
                        </p>
                    </div>
                    <div className={styles.action}>
                        {
                            payment?.status === "success" &&
                            <GreenRoundedButton onClick={goToDashboard}>
                                {t("user:InspectionPaymentStatus.ContinueButton")}
                            </GreenRoundedButton>
                        }
                        {
                            payment?.status === "expired" && payment?.url &&
                            <GreenRoundedButton onClick={retryPayment}>
                                {t("user:InspectionPaymentStatus.RetryButton")}
                            </GreenRoundedButton>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}