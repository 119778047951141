import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {InspectionPhoto} from "@appTypes/files";
import {Form, Formik, FormikHelpers} from "formik";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getFormData, getFormValues, setFormData} from "@helpers/multiStepForm";
import {NEW_INSPECTION_FORM_DATA_KEY} from "@helpers/constants/inspection";
import {HintInterceptor} from "@helpers/components/HintInterceptor/HintInterceptor";
import {WhiteCameraImageInput} from "@components/common/inputs/WhiteCameraImageInput/WhiteCameraImageInput";

import LeftFrontTireHint from "@assets/media/hints/LeftFrontTireHint.jpg"
import RightFrontTireHint from "@assets/media/hints/RightFrontTireHint.jpg"
import LeftRearTireHint from "@assets/media/hints/LeftRearTireHint.jpg"
import RightRearTireHint from "@assets/media/hints/RightRearTireHint.jpg"
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";
import {carTiresFormValidationSchema} from "@helpers/validationSchemas/new-inspection";

interface CarTiresProps {
    title?: string,
    description?: string,
}

interface Values {
    left_front_tire: InspectionPhoto | null,
    right_front_tire: InspectionPhoto | null,
    left_rear_tire: InspectionPhoto | null,
    right_rear_tire: InspectionPhoto | null,
}

export const CarTires: FunctionComponent<CarTiresProps> = () => {
    const initialValues: Values = {
        left_front_tire: null,
        right_front_tire: null,
        left_rear_tire: null,
        right_rear_tire: null,
        ...getFormValues(NEW_INSPECTION_FORM_DATA_KEY, 'left_front_tire', 'right_front_tire', 'left_rear_tire', 'right_rear_tire'),
    }

    const navigate = useNavigate()
    const location = useLocation()

    const {t} = useTranslation()

    const onSubmit = async (values: Values, {setSubmitting, setErrors}: FormikHelpers<Values>) => {
        setFormData(NEW_INSPECTION_FORM_DATA_KEY, {
            ...getFormData(NEW_INSPECTION_FORM_DATA_KEY),
            ...values
        })

        navigate(location, {
            state: {
                activeStep: 5
            }
        })

        setSubmitting(false)
    }

    return (
        <div className={styles.step}>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={carTiresFormValidationSchema}>
                {({
                      isSubmitting,
                      errors,
                  }) => (
                    <Form className={styles.form}>
                        <HintInterceptor
                            hintKey="left_front_tire"
                            hintTitle={t("user:NewInspection.CarTires.hints.LeftFrontTire.title")}
                            hintDescription={t("user:NewInspection.CarTires.hints.LeftFrontTire.description")}
                            hintFileSource={LeftFrontTireHint} hintFileType="image">
                            <WhiteCameraImageInput name="left_front_tire"
                                                   label={t("user:NewInspection.CarTires.LeftFrontTireImageInput.label")}/>
                        </HintInterceptor>
                        <HintInterceptor
                            hintKey="right_front_tire"
                            hintTitle={t("user:NewInspection.CarTires.hints.RightFrontTire.title")}
                            hintDescription={t("user:NewInspection.CarTires.hints.RightFrontTire.description")}
                            hintFileSource={RightFrontTireHint} hintFileType="image">
                            <WhiteCameraImageInput name="right_front_tire"
                                                   label={t("user:NewInspection.CarTires.RightFrontTireImageInput.label")}/>
                        </HintInterceptor>
                        <HintInterceptor
                            hintKey="left_rear_tire"
                            hintTitle={t("user:NewInspection.CarTires.hints.LeftRearTire.title")}
                            hintDescription={t("user:NewInspection.CarTires.hints.LeftRearTire.description")}
                            hintFileSource={LeftRearTireHint} hintFileType="image">
                            <WhiteCameraImageInput name="left_rear_tire"
                                                   label={t("user:NewInspection.CarTires.LeftRearTireImageInput.label")}/>
                        </HintInterceptor>
                        <HintInterceptor
                            hintKey="right_rear_tire"
                            hintTitle={t("user:NewInspection.CarTires.hints.RightRearTire.title")}
                            hintDescription={t("user:NewInspection.CarTires.hints.RightRearTire.description")}
                            hintFileSource={RightRearTireHint} hintFileType="image">
                            <WhiteCameraImageInput name="right_rear_tire"
                                                   label={t("user:NewInspection.CarTires.RightRearTireImageInput.label")}/>
                        </HintInterceptor>
                        <div className={styles.submitButton}>
                            <GreenRoundedButton type="submit" disabled={isSubmitting || !!Object.values(errors).length}>
                                {t("common:Inspection.NextStepButton")}
                            </GreenRoundedButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}