import {FC, useState} from "react";
import {GreenButton} from "@components/common/buttons/GreenButton/GreenButton";
import {MassMailingPopup} from "@components/admin/popups/MassMailingPopup/MassMailingPopup";

const MassMailing: FC = () => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    return (
        <>
            <GreenButton onClick={() => setModalOpen(true)}>
                Mass Mailing
            </GreenButton>
            <MassMailingPopup open={modalOpen} onClose={() => setModalOpen(false)} />
        </>
    );
}

export {
    MassMailing,
}
