import {FunctionComponent, useEffect} from "react"

import styles from "./styles.module.css"
import {UserHeader} from "@components/headers/UserHeader/UserHeader";
import {Outlet, Route, Routes, useLocation} from "react-router-dom";
import { Footer } from "@components/footers/user/Footer/Footer";

export const UserLayout: FunctionComponent = () => {
    const location = useLocation()

    useEffect(() => {
        window.scrollTo({
            top: 0
        });
    }, [location.key])

    return (
        <div className={styles.layout}>
            <UserHeader/>
            <Outlet/>
            <Routes>
                <Route index element={<Footer/>}/>
                <Route path="contact-us" element={<Footer/>}/>
                <Route path="*" element={null}/>
            </Routes>
        </div>
    )
}