import {FunctionComponent, useEffect, useState} from "react";

import {useField} from "formik"

import styles from "./styles.module.css"
import {Company} from "@appTypes/inspection";
import {InputError} from "@components/common/InputError/InputError";

interface WhiteSelectCompanyInputProps {
    id?: string,
    name: string,
    placeholder?: string,
    onChange?: (event: unknown) => void,
    label: string,
    companies?: Company[],
    disabled?: boolean,
}

export const WhiteSelectCompanyInput: FunctionComponent<WhiteSelectCompanyInputProps> = ({
                                                                                             id,
                                                                                             name,
                                                                                             label,
                                                                                             onChange,
                                                                                             companies,
                                                                                             disabled = false
                                                                                         }) => {
    const [field, meta, helpers] = useField({id: id, name: name})

    const [selected, setSelected] = useState<number[]>(meta.initialValue || [])

    useEffect(() => {
        if (!disabled) {
            helpers.setValue([])
            setSelected([])
        }
    }, [companies])

    const onClick = (event: unknown, company_id: number) => {
        if (disabled) return

        let newValues = []

        if (onChange) {
            onChange(event)
        }
        if (selected.includes(company_id)) {
            newValues = selected.filter(value => value !== company_id)
        } else {
            newValues = [...selected, company_id]
        }
        setSelected(newValues)
        helpers.setValue(newValues)
    }

    if (!companies || !(Array.isArray(companies) && companies.length)) {
        return null
    }

    return (
        <div className={styles.container}>
            <label htmlFor={id} className={styles.label}>{label}</label>
            <InputError error={meta.error || ""} touched={meta.touched}/>
            <div className={styles.companies}>
                {
                    companies?.map((obj, idx) => (
                        <div className={`${styles.company} ${selected.includes(obj.id) ? styles.selected : ""}`}
                             onClick={(event) => {
                                 onClick(event, obj.id)
                             }} key={idx}>
                            <img src={obj.logo} alt={obj.name}/>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}
