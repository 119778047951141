import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {OverlayingPopup} from "@components/common/popups/OverlayingPopup/OverlayingPopup";

interface InspectionAgreementPopupProps {
    open: boolean,
    onClose: () => void,
}

export const InspectionAgreementPopup: FunctionComponent<InspectionAgreementPopupProps> = ({open, onClose}) => {
    return (
        <OverlayingPopup isOpened={open} onClose={onClose}>
            <div className={styles.contents}>
                <div className={styles.header}>
                    <h3>VEHICLE REQUIREMENTS TO PASS THE INSPECTION</h3>
                </div>
                <div className={styles.content}>
                    <br/><br/>
                    Before submitting the vehicle inspection with insve.com, you must confirm that your vehicle is fully
                    drivable and operational and has no problems with the braking system and steering systems. During
                    driving, you do not notice any deviations from the norm, as well as unusual sounds. You are fully
                    responsible for providing insve.com with any information about hidden damages to your vehicle that
                    cannot be checked or recognized during the normal visual vehicle inspection procedures.
                    <br/><br/>
                    Beyond the universal requirements applicable in all states, some states have their criteria. Certain
                    states prohibit commercial branding on cars, and some may require specialized licenses like TNC or
                    business licenses. For instance, some states allow cars no older than 15 years, while others mandate
                    even newer vehicles. Regarding the vehicle inspection, the specific items subject to inspection can
                    vary by state. However, all states have a minimum inquiry of 19 points that need inspection,
                    including:
                    <br/><br/>
                    <ul className={styles.list}>
                        <li>Horn</li>
                        <li>Speedometer</li>
                        <li>Bumpers</li>
                        <li>Foot Brakes</li>
                        <li>Emergency/Parking Brake</li>
                        <li>Steering Mechanism</li>
                        <li>Windshield</li>
                        <li>Headlights</li>
                        <li>Tail Lights</li>
                        <li>Turn Indicator Lights</li>
                        <li>Stop Lights</li>
                        <li>Safety Belts for Driver and Passenger</li>
                        <li>Rear Window and other glass</li>
                        <li>Windshield wipers</li>
                        <li>Muffler and Exhaust system</li>
                        <li>Tires, including tread depth</li>
                        <li>Front Seat Adjustment</li>
                        <li>Doors (open/close/lock)</li>
                        <li>Interior and Exterior rear-view mirrors</li>
                    </ul>
                    <br/>
                    Some states might require more extensive inspections, such as 22-point or 25-point checks. It's
                    important to note that these inspections aim to ensure the vehicle is suitable for transporting
                    people from one point to another, rather than checking every single aspect of the car. While some
                    mechanics perform exhaustive 200-point inspections for pre-owned vehicles, the mandated rideshare
                    inspection doesn't demand that level of detail.
                </div>
            </div>
        </OverlayingPopup>
    )
}