import {Navigate, Route, Routes} from "react-router-dom";
import {Dashboard as AdminDashboard} from "@pages/admin/Dashboard/Dashboard";
import {AdminLayout} from "@layouts/AdminLayout/AdminLayout";
import {Requests} from "@pages/admin/Requests/Requests";
import {Archive} from "@pages/admin/Archive/Archive";
import {PermittedComponent} from "@helpers/components/PermittedComponent/PermittedComponent";
import {ActiveInspectionDetails} from "@pages/admin/ActiveInspectionDetails/ActiveInspectionDetails";
import {ArchiveInspectionDetails} from "@pages/admin/ArchiveInspectionDetails/ArchiveInspectionDetails";
import {Settings} from "@pages/admin/Settings/Settings";

export const InspectorRoutes = () => (
    <PermittedComponent allowedRoles={["inspector"]}>
        <Routes>
            <Route element={<AdminLayout/>}>
                <Route index element={<AdminDashboard/>}/>
                <Route path="requests" element={<Requests/>}/>
                <Route path="archive" element={<Archive/>}/>
                <Route path="settings" element={<Settings/>}/>
                <Route path="requests/:id" element={<ActiveInspectionDetails/>}/>
                <Route path="archive/:id" element={<ArchiveInspectionDetails/>}/>
            </Route>
            <Route element={<Navigate to="/" replace/>} path="/*"/>
        </Routes>
    </PermittedComponent>
)