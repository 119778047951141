export const getAmericanFormattedDateTimeString = (date: Date): string => {
    const localDate = new Date(date.toString())

    let hours = localDate.getHours()
    let minutes = localDate.getMinutes()

    const ampm = hours >= 12 ? 'pm' : 'am'

    hours = hours % 12
    hours = hours ? hours : 12

    return `${localDate.getMonth() + 1}/${localDate.getDate()}/${localDate.getFullYear()} ${hours}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`
}

export const getEuropeFormattedDateTimeString = (date: Date): string => {
    const localDate = new Date(date.toString())

    let hours = localDate.getHours()
    let minutes = localDate.getMinutes()

    return `${localDate.getDate()}.${localDate.getMonth() + 1}.${localDate.getFullYear()} ${hours}:${minutes < 10 ? '0' + minutes : minutes}`
}
