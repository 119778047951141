import {FunctionComponent, ReactNode} from "react";

import styles from "./styles.module.css"
import {Portal} from "@helpers/components/Portal/Portal";
import {RemoveScroll} from 'react-remove-scroll';

export interface OverlayingPopupProps {
    onClose: () => void,
    isOpened: boolean,
    children: ReactNode
}

export const OverlayingPopup: FunctionComponent<OverlayingPopupProps> = ({onClose, isOpened, children}) => {
    if(!isOpened) return null

    return (
        <Portal>
            <div className={styles.container} role="dialog">
                <div className={styles.overlay} role="button" tabIndex={0} onClick={onClose}/>
                {children}
            </div>
        </Portal>
    );
}
