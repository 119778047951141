import {FunctionComponent, ReactNode, Suspense} from "react"

import styles from "./styles.module.css"
import {useAppDispatch, useAppSelector} from "@features/redux/store";
import {getMe, getMyToken, logout} from "@features/auth/slice";
import {Portal} from "@helpers/components/Portal/Portal";
import {TwoFactorAuthScreenForm} from "@components/common/TwoFactorAuthScreenForm/TwoFactorAuthScreenForm";
import {TransparentButton} from "@components/common/buttons/TransparentButton/TransparentButton";
import {ChevronLeft} from "@mui/icons-material";
import {RemoveScroll} from 'react-remove-scroll';

interface TwoFactorAuthScreenProps {
    children: ReactNode | ReactNode[],
}

export const TwoFactorAuthScreen: FunctionComponent<TwoFactorAuthScreenProps> = ({children}) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(getMe)
    const token = useAppSelector(getMyToken)

    const logoutMe = () => {
        dispatch(logout())
    }

    if (!user || !token || (!user.two_factor_auth_provider || !user.two_factor_auth_provider.verified)) {
        return (<>{children}</>)
    }

    if (token.two_factor_auth_verified) {
        return (<>{children}</>)
    }

    return (
        <Portal>
            <RemoveScroll>
                <div className={styles.screen}>
                    <TransparentButton onClick={logoutMe} className={styles.logoutButton}>
                        <ChevronLeft/> Выйти
                    </TransparentButton>
                    <Suspense fallback="Loading...">
                        <TwoFactorAuthScreenForm/>
                    </Suspense>
                </div>
            </RemoveScroll>
        </Portal>
    )
}