import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {OverlayingPopup} from "@components/common/popups/OverlayingPopup/OverlayingPopup";

interface TermsOfUsePopupProps {
    open: boolean,
    onClose: () => void,
}

export const TermsOfUsePopup: FunctionComponent<TermsOfUsePopupProps> = ({open, onClose}) => {
    return (
        <OverlayingPopup isOpened={open} onClose={onClose}>
            <div className={styles.contents}>
                <div className={styles.header}>
                    <h3>The User Agreement</h3>
                </div>
                <div className={styles.content}>
                    INSVE.COM (operated by MFC, LLC)(“Insve.com”, “us”, “we” or "our" as the context indicates) operates
                    and owns Insve.com including this website, the mobile version of it and our mobile application (“The
                    Sites”). Insve.com provides the service of online visual vehicle inspection (references to the Sites
                    include “The Services”).
                    <br/><br/>
                    This User Agreement constitutes an arrangement between you and Insve.com that regulates your
                    utilization of and entry to the Sites, their features, their functions, the material they contain,
                    and the Services we provide. When you use or access the Sites you affirm and guarantee that you are
                    of lawful age to acknowledge this User Agreement and establish a legally binding contract with
                    Insve.com.
                    <br/><br/>
                    Insve.com holds the authority to alter, halt, adjust, or terminate the entire or partial
                    functionality of the Sites at its sole discretion, whether with or without prior notification.
                    Insve.com cannot be held accountable if, for any reason, any portion of the Sites becomes
                    inaccessible at any time. Insve.com retains the prerogative to prohibit or reject access to any of
                    the Sites to any individual, at any point, and for any rationale. Insve.com is not bound to rectify
                    or refresh any data or content on the Sites. The data published on the Sites was accurate during its
                    publication period. Insve.com reserves the right to enact changes and enhancements without notice
                    and bears no responsibility for direct or indirect damages arising from errors, omissions, or
                    inconsistencies.
                    <br/><br/>
                    The utilization of The Sites and its associated services is contingent upon your adherence to the
                    User Agreement provided below. Kindly review the subsequent information attentively. Should you
                    disagree with the terms, promptly exit the site without engaging with or using any of the Services.
                    <br/><br/>
                    Upon accessing any pages of the Sites or creating an account, employing services offered via the
                    Sites, posting, submitting, transmitting, or uploading any information or content through the Sites,
                    you explicitly affirm that you've perused, comprehended, and consented to the terms, conditions,
                    limitations, notices, and commitments outlined in this User Agreement. This encompasses any
                    applicable policies and disclaimers cited here or on the Sites. If you do not concur with this User
                    Agreement and the other policies, refrain from utilizing the Sites.
                    <br/><br/>
                    Usage Restrictions
                    <br/><br/>
                    The Sites belong to Insve.com and are protected by state, federal, or international copyright and
                    trademark laws. You're not allowed to reprint, republish, modify, or distribute any part of the
                    content without written consent from Insve.com. The Sites can be used only for personal or internal
                    business purposes. You must maintain any copyright notices on downloaded materials and follow
                    relevant end-user license agreements. Insve.com reserves all rights not explicitly granted by this
                    User Agreement or applicable end-user license agreements.
                    <br/><br/>
                    By using the Sites, you assure INSVE.COM that you won't employ them for any unlawful or forbidden
                    purposes outlined in this User Agreement. You also commit to refraining from using the Sites in the
                    following ways:
                    - Violating any applicable laws or intellectual property rights
                    - Transmitting materials that fail to adhere to the Content Standards
                    - Engaging in any other activities not explicitly allowed by this User Agreement
                    - Endangering the security or proper functioning of the Sites
                    - Attempting to create a competing product or service
                    <br/><br/>
                    Security Measures
                    <br/><br/>
                    It's important to keep your account information confidential and notify Insve.com about any
                    unauthorized access or use. Using a unique username and password helps enhance security, and the
                    platform has the authority to disable accounts if needed.
                    <br/><br/>
                    User Content
                    <br/><br/>
                    The Sites may offer Services that involve sharing User Content, such as answers, comments, and
                    documents. This content is considered non-confidential and non-proprietary. You retain ownership of
                    your User Content but grant Insve.com a broad license to use, modify, and distribute it. Insve.
                    com isn't obligated to use or respond to User Content, and you're responsible for ensuring your
                    content complies with the User Agreement and Content Standards. You represent that you have the
                    rights to your User Content and that it adheres to the guidelines outlined in the Requirements for
                    user content.
                    <br/><br/>
                    Requirements for User Content
                    <br/><br/>
                    By using the Insve.com App, you commit to refraining from sharing User Content that infringes on
                    others’ intellectual property rights, violates laws, or contains offensive materials. If you have
                    questions or concerns about data practices, you can contact the Insve.com App data protection office
                    through the provided link. User Content should not be defamatory, abusive, discriminatory, or
                    unlawful. It shouldn’t misrepresent identities, promote explicit or violent content, or deceive
                    others. Insve.com reserves the right to remove or refuse any User Content and may take action
                    against violations. The platform can cooperate with law enforcement and disclose information if
                    required. Insve.com isn’t liable for User Content actions. While monitoring may happen, timely
                    removal isn’t guaranteed. Insve.com doesn’t endorse or validate User Content.
                    <br/><br/>
                    INSVE.COM assumes no liability or accountability to individuals for the execution or lack thereof of
                    the tasks detailed in this segment of the User agreement. By utilizing this platform, you relinquish
                    any claims and release INSVE.COM, along with its affiliates, licensors, and service providers, from
                    any legal demands arising due to actions carried out by these entities throughout or following their
                    inquiries, as well as actions undertaken as a result of investigations conducted by INSVE.COM or law
                    enforcement agencies.
                    <br/><br/>
                    Rights of the Intellectual Property
                    <br/><br/>
                    Insve.com, along with its licensors and other providers, holds ownership over the Sites,
                    encompassing all content, features, and functionality within them. This includes information,
                    software code, text, images, video, audio, and the arrangement of such elements, as well as
                    documents, resources, recommendations, and other materials generated through the Sites (referred to
                    as "Site Content"). These assets are protected by copyright, trademark, and other laws and may only
                    be used as permitted by the User Agreement. No transfer of rights, title, or interest in the Sites
                    or Site Content occurs, and all ungranted rights are reserved by Insve.com. Any unauthorized use of
                    the Sites or Site Content constitutes a breach of the User Agreement and could violate copyright,
                    trademark, and other laws.
                    <br/><br/>
                    You're allowed to use the Sites solely for their intended purpose. You can download one copy of Site
                    Content onto a single computer and print materials for your use in learning about, evaluating, or
                    acquiring Services. However, you're not granted permission to print, copy, reproduce, distribute,
                    transmit, upload, download, store, publicly display, alter, or modify these materials beyond these
                    purposes. Furthermore, you are not authorized to use Insve.com icons, site addresses, or other
                    methods to hyperlink other websites with our Sites.
                    <br/><br/>
                    Trademark Rights Violations
                    <br/><br/>
                    Insve.com possesses and maintains complete rights over its registered and/or common law trademarks,
                    including trademark names, trade dress, logos, and designs. These trademarks, collectively referred
                    to as the "Trademarks," are safeguarded by legal regulations. This User Agreement and your
                    utilization of the Sites do not grant you a license to utilize the Insve.com Trademarks. Any
                    additional logos, designs, or slogans found on the Sites are the intellectual property of their
                    respective owners. Their usage adheres to legal requirements, licenses, and agreements.
                    <br/><br/>
                    Third-Party Content Regulations
                    <br/><br/>
                    The Sites might contain content from third parties. Apart from content by Insve.com, any statements,
                    opinions, articles, responses to questions, and other content reflect the views and responsibilities
                    of the respective provider. Insve.com is not accountable for the accuracy or content of materials
                    offered by third parties. Insve.
                    com does not guarantee the accuracy, completeness, or reliability of any third-party information on
                    the Sites.
                    <br/><br/>
                    Internal linking out of the Sites
                    <br/><br/>
                    Insve.com doesn't provide any guarantees about external websites you might access through this Site.
                    These non-Insve.com sites are independent, and Insve.com has no control over their content. A link
                    to a non-Insve.com site doesn't imply endorsement or responsibility for its content. It's your
                    responsibility to ensure that downloaded content is free from harmful elements like viruses or
                    trojans.
                    <br/><br/>
                    External Linking to the Sites
                    <br/><br/>
                    You're allowed to link to our Sites as long as it's done legally and doesn't exploit Insve.com's
                    reputation. However, you can't imply any association or endorsement by Insve.com without written
                    consent. Our Sites may integrate with social media, letting you share content responsibly. You
                    mustn't link from sites you don't control or display your content on other sites improperly. Your
                    linked website must adhere to our Content Standards. Unauthorized linking can be stopped by us at
                    any time, and we might disable social media features and links without notice.
                    <br/><br/>
                    Geographical limitations
                    <br/><br/>
                    Insve.com is designed for usage exclusively by individuals situated within the United States.
                    Insve.com does not assert that the Sites or any of their content are suitable or attainable beyond
                    the borders of the United States. Should you be accessing the Sites from a location outside the
                    United States, it is at your peril as it could potentially contravene legal regulations.
                    <br/><br/>
                    No Warranty of any Kind of Safety
                    <br/><br/>
                    INSVE.COM hereby explicitly disclaims all warranties of the safety of your vehicle, irrespective of
                    whether your vehicle successfully passes the visual inspection conducted by INSVE.COM.
                    <br/><br/>
                    It is duly acknowledged and mutually consented that the responsibility for the safety of your
                    vehicle rests solely upon you, the vehicle owner, and not upon INSVE.COM.
                    <br/><br/>
                    It is further acknowledged and mutually agreed that any vehicle inspection service extended to you
                    by INSVE.COM is exclusively predicated upon the information and visual documentation proffered by
                    you for the express purpose of evaluating the suitability of your vehicle for deployment as a
                    rideshare conveyance. It is explicitly understood and mutually conceded that INSVE.COM is neither
                    conducting an assessment of nor ensuring, the safety of your vehicle. In instances where you possess
                    any reservations regarding the safety of your vehicle, it is incumbent upon you to engage a
                    third-party entity qualified to administer such an assessment.
                    <br/><br/>
                    Concerning limitations on liability and the temporal window for initiating claims, it is expressly
                    provided that INSVE.COM, its service providers, employees, agents, officers, managers, directors,
                    affiliates, and their respective licensors, shall not be held liable for any form of damages, under
                    any legal theory, arising from or in connection with the use or inability to use the websites hosted
                    by INSVE.COM, any websites linked thereto, the contents of said sites, or any ancillary content on
                    said sites or affiliated platforms, including services or items procured through said sites or
                    affiliated platforms. The spectrum of said limitations encompasses direct, indirect, consequential,
                    special, exemplary, punitive, or incidental damages, encompassing but not confined to damages
                    arising from personal injury, emotional anguish, loss of revenue, loss of profits, or loss of data,
                    whether arising from tortuous conduct (including negligence) or contractual breach, even if such
                    ramifications are reasonably foreseeable.
                    <br/><br/>
                    Without derogating from the generality of the foregoing, it is specified that INSVE.COM will not be
                    held liable for any loss or damage traceable to distributed denial-of-service attacks, viruses, or
                    other forms of technologically deleterious content capable of compromising the integrity of your
                    computer hardware, software, data, or proprietary material, attributable to your interaction with
                    the aforementioned websites.
                    <br/><br/>
                    Any legal cause of action or claim arising from, or in any manner associated with, this user
                    agreement, the utilization of the websites or services, shall be instituted within a timeframe of
                    one (1) year after the accrual of a said cause of action. Should said timeframe elapse, any such
                    cause of action or claim shall be deemed irrevocably proscribed.
                    <br/><br/>
                    The INSVE.COM application (“App”) employs the data collated from its users, inclusive of recorded
                    interactions with customer support, after due notification and acquisition of consent. This
                    utilization is primarily directed towards enhancing customer support services, encompassing but not
                    limited to:
                    <br/><br/>
                    It is unequivocally recognized and acknowledged that the operational viability of the INSVE.COM
                    platform and services hinges upon the implementation of the aforementioned exclusions, limitations,
                    disclaimers of warranties, and restrictions on liability. Consequently, it is mutually understood
                    that these provisions constitute an equitable distribution of risk, given the prevailing
                    circumstances, and shall be upheld to the maximum extent permissible as stipulated by applicable
                    law.
                    <br/><br/>
                    The data acquired may additionally be employed for testing, research, analysis, and the development
                    of products, thereby fortifying the security and functionality of our services. This iterative
                    process also contributes to the introduction of novel features and products, whilst facilitating the
                    provision of insurance and financing solutions germane to our services.
                    <br/><br/>
                    Disclaimer of any other Warranties
                    <br/><br/>
                    The Sites, including any content or information contained within it or any site-related service, is
                    provided on an "as is" and "as available" basis, with all faults, with no representations or
                    warranties of any kind, either expressed or implied, other than those express warranties provided in
                    this user agreement. Neither insve.com nor any person or entity affiliated or associated with it
                    makes any warranty or representation concerning the completeness, security, safety, reliability,
                    quality, accuracy, currency, or availability of the sites, site content, and services. Without
                    limiting the foregoing, neither insve.com nor anyone associated with it represents or warrants that
                    the Sites will be accurate, reliable, error-free, or uninterrupted, that defects will be corrected,
                    that the site or server that makes it available is free of viruses or other harmful components, or
                    that the website or any services or items obtained through the website will otherwise meet your
                    expectations or needs.
                    <br/><br/>
                    Except as expressly provided herein, insve.com disclaims all warranties of any kind, whether express
                    or implied, statutory or otherwise, including but not limited to any warranties of merchantability,
                    fitness for a particular purpose, and non-infringement. You assume total responsibility and risk for
                    your use of the sites and insve.com services.
                    <br/><br/>
                    The foregoing applies to the greatest extent permissible under applicable law and does not exclude
                    or limit any warranties to the extent that they cannot be excluded or limited under applicable law,
                    in which case the foregoing limitations will apply solely to the extent legally permissible.
                    <br/><br/>
                    Refunds Policy
                    <br/><br/>
                    At INSVE.COM, we firmly believe in handling refund requests on a personalized basis. We understand
                    that situations vary, and occasionally, we may offer refunds for inspections at our discretion. Our
                    commitment is to fairness and ensuring that every customer's needs are addressed. We strive to
                    provide the best service possible, and in certain circumstances, we may deem it appropriate to grant
                    a refund. However, it's important to note that a refund is not an obligation on our part. We want to
                    acknowledge that even if your vehicle is found non-compliant with specific standards and thus
                    rejected, our team puts in dedicated effort and resources to offer you our services.
                    <br/><br/>
                    Indemnification Policy
                    <br/><br/>
                    We want to ensure your peace of mind when using INSVE.COM. To provide you with the utmost
                    protection, we kindly ask that you stand with us in defending the integrity of our platform.
                    <br/><br/>
                    By agreeing to this, you're helping us collectively shield INSVE.COM, its affiliates, and all those
                    involved, from any claims, liabilities, or damages that may arise from breaches of our User
                    Agreement or any misuse of our services. Your support allows us to continue delivering the
                    high-quality service you deserve, and we appreciate your cooperation in maintaining a secure and
                    reliable environment for all users.
                    <br/><br/>
                    Waivers
                    <br/><br/>
                    Your support and understanding of our policies are crucial in creating a harmonious user experience.
                    While we may choose to waive certain terms under specific circumstances, it's important to remember
                    that such actions don't diminish the importance of these terms as a whole. Additionally, if any part
                    of our User Agreement is deemed unenforceable by a court, it's intended that the remaining
                    provisions continue to guide our interactions, ensuring a balanced and effective working
                    relationship.
                    <br/><br/>
                    Government Law Policy
                    <br/><br/>
                    We firmly believe in transparency and setting clear expectations. As such, this User Agreement is
                    governed by the laws of the State of California, where INSVE.COM operates. We want you to feel
                    assured that we are committed to complying with the legal framework that governs us. By agreeing to
                    this, you acknowledge that any disputes or legal matters will be addressed in the state and federal
                    courts of California. This approach ensures a fair and consistent process for everyone involved,
                    fostering trust and confidence in our services.
                    <br/><br/>
                    Denial of Access to the Sites
                    <br/><br/>
                    At INSVE.COM, our goal is to provide a safe and reliable platform for all users. We are committed to
                    maintaining a respectful and cooperative environment. In the rare instance that access to our site
                    is denied due to non-compliance with our User Agreement, we kindly ask for your understanding. This
                    measure ensures that our site remains a trusted space for all users, protecting their interests and
                    maintaining the high standards we strive to uphold.
                    <br/><br/>
                    Entire Agreement
                    <br/><br/>
                    To promote clarity and ensure a seamless experience, this User Agreement stands as the comprehensive
                    understanding between you and INSVE.COM. It supersedes any prior discussions or agreements, both
                    written and oral, ensuring consistency and transparency in our interactions. As part of our
                    commitment to improvement, we may update the User Agreement from time to time. Each visit you make
                    to our site is a new opportunity for us to engage with you under the most current terms.
                    <br/><br/>
                    Contact INSVE.COM
                    <br/><br/>
                    We value your feedback and input as we continue to enhance our services. If you have any comments,
                    requests for support or other forms of communication, we encourage you to reach out to us at
                    support@insve.com. Your input is invaluable in helping us better serve you and the community as a
                    whole. We are dedicated to open communication and look forward to hearing from you.
                    <br/><br/>
                    Last Updated: 08/08/2023
                </div>
            </div>
        </OverlayingPopup>
    )
}