import {FunctionComponent, MouseEvent, ReactElement} from "react"

import styles from "./styles.module.css"

export interface HeaderProps {
    open?: boolean,
    onOpen?: (event?: MouseEvent<HTMLElement>) => void,
    onClose?: (event?: MouseEvent<HTMLElement>) => void,
    className?: string,
    wrapperClassName?: string,
    openIcon?: ReactElement | HTMLElement,
    closeIcon?: ReactElement | HTMLElement,
    children: ReactElement | ReactElement[],
}

const Header: FunctionComponent<HeaderProps> = ({
                                                    open,
                                                    onOpen,
                                                    onClose,
                                                    className = "",
                                                    wrapperClassName = "",
                                                    openIcon = "+",
                                                    closeIcon = "x",
                                                    children
                                                }) => {
    return (
        <div className={`${styles.header} ${className}`} onClick={() => open ? onClose!() : onOpen!()}>
            <>
                <div className={`${styles.wrapper} ${wrapperClassName}`}>
                    {children}
                </div>
                {open ? closeIcon : openIcon}
            </>
        </div>
    )
}

Header.displayName = 'BaseAccordionHeader'

export {
    Header
}
