import {FunctionComponent, ReactElement} from "react";
import {ScanQR} from "@components/admin/Connect2FAPopup/steps/ScanQR/ScanQR";
import {EnterCode} from "@components/admin/Connect2FAPopup/steps/EnterCode/EnterCode";
import {Success} from "@components/admin/Connect2FAPopup/steps/Success/Success";

import GoogleIcon from "@assets/icons/social/GoogleIcon.svg"

interface Step<T> {
    title?: ReactElement | string,
    description?: ReactElement | string,
    component: FunctionComponent<T>,
}

export const STEPS: Step<any>[] = [
    {
        title: <><img src={GoogleIcon} alt="GoogleIcon"/> Scan QR Code using App</>,
        description: "Install App and scan QR code",
        component: ScanQR,
    },
    {
        title: "Enter 2FA Code",
        description: "Enter code from app",
        component: EnterCode,
    },
    {
        title: "Success",
        description: "Authenticator installed successfully",
        component: Success,
    },
]
