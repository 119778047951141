import {FunctionComponent, ReactElement} from "react"

import styles from "./styles.module.css"
import {useLocation, useNavigate} from "react-router-dom";
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";
import {Form, Formik, FormikHelpers} from "formik";
import {WhiteInput} from "@components/common/inputs/WhiteInput/WhiteInput";
import {useTranslatedSchema} from "@helpers/hooks/useTranslatedSchema";
import {google2FAEnterCodeValidationSchema} from "@helpers/validationSchemas/google-2fa";
import {useConnectVerifyGoogleTwoFactorAuthMutation} from "@features/two_factor_auth/google/api";
import {convertApiFormErrors, isApiFormResponse} from "@helpers/errors";

interface EnterCodeProps {
    title?: ReactElement | string,
    description?: ReactElement | string,
}

interface Values {
    code: string,
}

const initialValues: Values = {
    code: "",
}

export const EnterCode: FunctionComponent<EnterCodeProps> = ({title, description}) => {
    const location = useLocation()
    const navigate = useNavigate()

    const schema = useTranslatedSchema(google2FAEnterCodeValidationSchema)

    const [connectVerifyGoogleTwoFactorAuth] = useConnectVerifyGoogleTwoFactorAuthMutation()

    const goToNextStep = async (values: Values, {setErrors}: FormikHelpers<Values>) => {
        try {
            await connectVerifyGoogleTwoFactorAuth({code: values.code}).unwrap()

            navigate(location, {
                state: {
                    activeStep: 2,
                }
            })
        }
        catch (e) {
            if (isApiFormResponse(e)) {
                const errors = convertApiFormErrors(e)

                setErrors(errors.errors)
            }
        }
    }

    return (
        <div className={styles.step}>
            <Formik initialValues={initialValues} onSubmit={goToNextStep} validationSchema={schema}>
                <Form className={styles.form}>
                    {title && <h2 className={styles.title}>{title}</h2>}
                    {description && <p className={styles.description}>{description}</p>}
                    <WhiteInput label="Code" name="code" type="text" inputMode="numeric" pattern="[-+]?[0-9]*[.,]?[0-9]+" placeholder="123456"/>
                    <div className={styles.nextStep}>
                        <GreenRoundedButton type="submit">
                            Confirm
                        </GreenRoundedButton>
                    </div>
                </Form>
            </Formik>
        </div>
    )
}