import * as yup from "yup"
import {InspectionPhoto} from "@appTypes/files";

export const inspectionPhotoSchema: yup.ObjectSchema<InspectionPhoto> = yup.object().shape({
    id: yup.number().required("Required"),
    file: yup.string().required("Required")
})

export const inspectionVideoSchema: yup.ObjectSchema<InspectionPhoto> = yup.object().shape({
    id: yup.number().required("Required"),
    file: yup.string().required("Required")
})

export const inspectionCertificateSchema: yup.ObjectSchema<InspectionPhoto> = yup.object().shape({
    id: yup.number().required("Required"),
    file: yup.string().required("Required")
})

export default yup
