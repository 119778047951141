import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {Company, Inspection} from "@appTypes/inspection";
import {Form, Formik} from "formik";
import {
    InspectionCertificateInput
} from "@components/admin/inputs/InspectionCertificateInput/InspectionCertificateInput";
import {GreenButton} from "@components/common/buttons/GreenButton/GreenButton";

interface UploadInspectionCertificateProps {
    company: Company,
    inspection: Inspection,
}

export const UploadInspectionCertificate: FunctionComponent<UploadInspectionCertificateProps> = ({company, inspection}) => {
    const uploadedCertificate = inspection.certificates.find((certificate) => certificate.company.id === company.id) || null

    console.log(uploadedCertificate)

    return (
        <div className={styles.contents}>
            <Formik initialValues={{certificate: uploadedCertificate}} onSubmit={() => {}}>
                <Form className={styles.form}>
                    <InspectionCertificateInput name="certificate" label="Certificate" inspection={inspection} company={company}/>
                </Form>
            </Formik>
        </div>
    )
}