import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {useTranslation} from "react-i18next";

interface InspectionFormHeaderProps {
    stepNumber: number | string,
    title: string,
}

export const InspectionFormHeader: FunctionComponent<InspectionFormHeaderProps> = ({stepNumber, title}) => {
    const { t } = useTranslation()

    return (
        <h4 className={styles.title}>
            <span className={styles.betony}>
                {stepNumber}
            </span>
            {t(title)}
        </h4>
    )
}