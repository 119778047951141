import {api} from "@features/redux/api";
import {Proposal} from "@appTypes/proposal";
import {Inspection} from "@appTypes/inspection";

type newProposalQueryResult = Proposal
type newProposalQueryArgs = Pick<Proposal, "name" | "email" | "message">

type proposalListPaginatedQueryResult = {
    count: number,
    list: Inspection[]
}
type proposalListQueryResult = Proposal[]
type proposalsListQueryArgs = {
    search?: string,
    ordering?: "id" | "-id",

    offset?: number,
    limit?: number,
}

type proposalDetailsQueryResult = Proposal
type proposalDetailsQueryArgs = Pick<Proposal, "id">

type deleteProposalQueryResult = Proposal
type deleteProposalQueryArgs = Pick<Proposal, "id">

export const proposalApi = api.enhanceEndpoints({addTagTypes: ["Proposal"]}).injectEndpoints({
    endpoints: builder => ({
        newProposal: builder.mutation<newProposalQueryResult, newProposalQueryArgs>({
            query: ({...args}) => ({
                url: `proposals/new/`,
                method: "POST",
                body: args,
            }),
            invalidatesTags: (result) => {
                if (!result) {
                    return ["Proposal"]
                }
                return [{type: "Proposal" as const, id: result.id}]
            }
        }),
        listProposals: builder.query<proposalListPaginatedQueryResult & proposalListQueryResult, proposalsListQueryArgs>({
            query: ({...params}) => ({
                url: `proposals/list`,
                method: "GET",
                params: params,
            }),
            providesTags: (result) => {
                if (!result) {
                    return ["Proposal"]
                }
                return [...(result?.list || result).map(({id}) => ({type: "Proposal" as const, id}))]
            }
        }),
        proposalsDetails: builder.query<proposalDetailsQueryResult, proposalDetailsQueryArgs>({
            query: ({id}) => ({
                url: `proposals/list/${id}`,
                method: "GET",
            }),
            providesTags: (result) => {
                if (!result) {
                    return ["Proposal"]
                }
                return [{type: "Proposal" as const, id: result.id}]
            }
        }),
        deleteProposal: builder.mutation<deleteProposalQueryResult, deleteProposalQueryArgs>({
            query: ({id}) => ({
                url: `proposals/list/${id}/`,
                method: "DELETE",
            }),
            invalidatesTags: (result) => {
                if (!result) {
                    return ["Proposal"]
                }
                return [{type: "Proposal" as const, id: result.id}]
            }
        }),
    })
})

export const {
    useNewProposalMutation,
    useListProposalsQuery,
    useProposalsDetailsQuery,
    useDeleteProposalMutation,
} = proposalApi
