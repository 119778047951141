import {FunctionComponent, ReactElement, useEffect} from "react"

import styles from "./styles.module.css"
import {useLocation, useNavigate} from "react-router-dom";
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";
import {
    useConnectGoogleTwoFactorAuthQuery
} from "@features/two_factor_auth/google/api";

import {AppStoreButton, ButtonsContainer, GooglePlayButton} from "react-mobile-app-button";

interface ScanQRPRops{
    title?: ReactElement | string,
    description?: ReactElement | string,
}

export const ScanQR: FunctionComponent<ScanQRPRops> = ({title, description}) => {
    const location = useLocation()
    const navigate = useNavigate()

    const {data: ConnectGoogle2FAData, isLoading} = useConnectGoogleTwoFactorAuthQuery()

    const goToNextStep = () => {
        navigate(location, {
            state: {
                activeStep: 1,
            }
        })
    }

    return (
        <div className={styles.step}>
            {title && <h2 className={styles.title}>{title}</h2>}
            {description && <p className={styles.description}>{description}</p>}
            <div className={styles.QRCodeContainer}>
                <div className={styles.QRCode}>
                    {isLoading ? "Loading..." : <img src={`data:image/png;base64, ${ConnectGoogle2FAData?.qr_code}`} alt="ConnectGoogle2FADataQRCode"/>}
                </div>
            </div>
            <div className={styles.buttonsContainer}>
                <GooglePlayButton url="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"/>
                <AppStoreButton url="https://apps.apple.com/ru/app/google-authenticator/id388497605"/>
            </div>
            <div className={styles.nextStep}>
                <GreenRoundedButton onClick={goToNextStep}>
                    I have installed Google Authenticator
                </GreenRoundedButton>
            </div>
        </div>
    )
}