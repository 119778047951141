import {FunctionComponent, useState} from "react"

import styles from "./styles.module.css"
import GoogleIcon from "@assets/icons/social/GoogleIcon.svg";
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";
import {Connect2FAPopup} from "@components/admin/Connect2FAPopup/Connect2FAPopup";
import {useAppSelector} from "@features/redux/store";
import {getMe, getMyToken} from "@features/auth/slice";
import {useDisconnectGoogleTwoFactorAuthMutation} from "@features/two_factor_auth/google/api";
import {convertApiError, isApiResponse} from "@helpers/errors";

export const Connect2FA: FunctionComponent = () => {
    const user = useAppSelector(getMe)
    const token = useAppSelector(getMyToken)

    const is2FAEnabled = user && token && (user.two_factor_auth_provider && user.two_factor_auth_provider.verified && token.two_factor_auth_verified)

    const [connect2FAPopupOpen, setConnect2FAPopupOpen] = useState<boolean>(false)

    const [disconnectGoogleTwoFactorAuth, {isLoading}] = useDisconnectGoogleTwoFactorAuthMutation()

    const toggle2FA = async () => {
        if (is2FAEnabled) {
            try {
                await disconnectGoogleTwoFactorAuth().unwrap()
            }
            catch (e) {
                if (isApiResponse(e)) {
                    const errors = convertApiError(e)

                    console.error(errors.message, errors.code)
                }
            }
        }
        else {
            setConnect2FAPopupOpen(true)
        }
    }

    return (
        <>
            <div className={styles.card}>
                <div className={styles.header}>
                    <img src={GoogleIcon} alt="GoogleIcon" className={styles.logo}/>
                    <div className={styles.heading}>
                        <h3 className={styles.title}>Google Authenticator</h3>
                        <p className={styles.description}>Connected to your Company via service account</p>
                    </div>
                </div>
                <GreenRoundedButton onClick={toggle2FA} disabled={isLoading}>
                    {is2FAEnabled ? "Disconnect" : "Connect"}
                </GreenRoundedButton>
            </div>
            <Connect2FAPopup isOpened={connect2FAPopupOpen} onClose={() => setConnect2FAPopupOpen(false)}/>
        </>
    )
}