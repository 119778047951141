import Yup from "@helpers/yup-extended";
import {TFunction} from "i18next";

export const createUserValidationSchema = (t: TFunction) => Yup.object().shape({
    email: Yup.string().min(4).max(50).required(),
    phone_number: Yup.string().required(t("validation:required") as string).matches(/^(?:\+1\s?)?(\d{10}|\(\d{3}\)\s?\d{3}-\d{4}|\d{3}-\d{3}-\d{4})$/,
        t("validation:invalid.phone") as string),
    first_name: Yup.string().min(2).max(25).required(),
    last_name: Yup.string().min(2).max(25).required(),
    role: Yup.string().required(),
    password: Yup.string()
        .required(t("validation:required")?.toString())
        .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/, 'Weak password')
    ,
    password_confirmation: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match'),
})

export const updateUserValidationSchema = (t: TFunction) => Yup.object().shape({
    email: Yup.string().min(4).max(50).required(),
    phone_number: Yup.string().required(t("validation:required") as string).matches(/^(?:\+1\s?)?(\d{10}|\(\d{3}\)\s?\d{3}-\d{4}|\d{3}-\d{3}-\d{4})$/,
        t("validation:invalid.phone") as string),
    first_name: Yup.string().min(2).max(25).required(),
    last_name: Yup.string().min(2).max(25).required(),
})

export const updateUserPasswordValidationSchema = (t: TFunction) => Yup.object().shape({
    new_password: Yup.string()
        .required(t("validation:required")?.toString())
        .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/, 'Weak password')
    ,
    new_password_confirmation: Yup.string().oneOf([Yup.ref('new_password')], 'Passwords must match'),
})
