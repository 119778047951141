import Yup from "@helpers/yup-extended"

import {TFunction} from "i18next";

export const signInFormValidationSchema = (t: TFunction) => Yup.object().shape({
    email: Yup.string().email(t("validation:invalid.email")?.toString()).required(t("validation:required")?.toString()),
    password: Yup.string()
        .required(t("validation:required")?.toString())
});

export const signUpFormValidationSchema = (t: TFunction) => Yup.object().shape({
    first_name: Yup.string().required(t("validation:required")?.toString()),
    last_name: Yup.string().required(t("validation:required")?.toString()),
    email: Yup.string().email(t("validation:invalid.email")?.toString()).required(t("validation:required")?.toString()),
    phone_number: Yup.string().required(t("validation:required")?.toString()).matches(/^(?:\+1\s?)?(\d{10}|\(\d{3}\)\s?\d{3}-\d{4}|\d{3}-\d{3}-\d{4})$/,
        t("validation:invalid.phone")?.toString()),
    password: Yup.string()
        .required(t("validation:required")?.toString())
        .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/, 'Weak password')
    ,
    password_confirmation: Yup.string().oneOf([Yup.ref('password')], t('validation:invalid.passwordEquality')?.toString()),
    user_agreement: Yup.boolean().isTrue("You must agree with user agreement"),
});

export const finishSignUpFormValidationSchema = (t: TFunction) => Yup.object().shape({
    first_name: Yup.string().required(t("validation:required")?.toString()),
    last_name: Yup.string().required(t("validation:required")?.toString()),
    email: Yup.string().email(t("validation:invalid.email")?.toString()).required(t("validation:required")?.toString()),
    phone_number: Yup.string().required(t("validation:required")?.toString()).matches(/^(?:\+1\s?)?(\d{10}|\(\d{3}\)\s?\d{3}-\d{4}|\d{3}-\d{3}-\d{4})$/,
        t("validation:invalid.phone")?.toString()),
    user_agreement: Yup.boolean().isTrue("You must agree with user agreement"),
});

export const forgotPasswordSchema = (t: TFunction) => Yup.object().shape({
    email: Yup.string().email(t("validation:invalid.email")?.toString()).required(t("validation:required")?.toString()),
});

export const resetPasswordSchema = (t: TFunction) => Yup.object().shape({
    password: Yup.string()
        .required(t("validation:required")?.toString())
        .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/, 'Weak password')
    ,
    password_confirmation: Yup.string().oneOf([Yup.ref('password')], t('validation:invalid.passwordEquality')?.toString()),
});
