import {Navigate, Route, Routes} from "react-router-dom";
import {UserLayout} from "@layouts/UserLayout/UserLayout";
import {Home} from "@pages/user/Home/Home";
import {MobileLayout} from "@layouts/MobileLayout/MobileLayout";
import {Dashboard as UserDashboard} from "@pages/user/Dashboard/Dashboard";
import {NewInspection} from "@pages/user/NewInspection/NewInspection";
import {InspectionPaymentStatus} from "@components/user/InspectionPaymentStatus/InspectionPaymentStatus";
import {PermittedComponent} from "@helpers/components/PermittedComponent/PermittedComponent";
import {ReInspection} from "@pages/user/ReInspection/ReInspection";
import {InspectionDetails} from "@pages/user/InspectionDetails/InspectionDetails";
import {ContactUs} from "@pages/user/ContactUs/ContactUs";

export const UserRoutes = () => (
    <PermittedComponent allowedRoles={["user"]}>
        <Routes>
            <Route element={<UserLayout/>}>
                <Route index element={<Home/>}/>
                <Route path="contact-us" element={<ContactUs/>}/>
                <Route element={<MobileLayout/>}>
                    <Route path="dashboard" element={<UserDashboard/>}/>
                    <Route path="new_inspection" element={<NewInspection/>}/>
                    <Route path="re-inspect/:id" element={<ReInspection/>}/>
                    <Route path="details/:id" element={<InspectionDetails/>}/>
                </Route>
                <Route path="payment-status/:id" element={<InspectionPaymentStatus/>}/>
                <Route element={<Navigate to="/dashboard" replace/>} path="sign_in"/>
                <Route element={<Navigate to="/dashboard" replace/>} path="sign_up"/>
                <Route element={<Navigate to="/dashboard" replace/>} path="sign_up/*"/>
                <Route element={<Navigate to="/" replace/>} path="/*"/>
            </Route>
        </Routes>
    </PermittedComponent>
)