import {Dispatch, SetStateAction, useEffect, useState} from "react";
import { useLocalStorage } from "./useLocalStorage";

type HintsObjectType = {
    [hintKey: string]: boolean
}

export const useHint = (hintKey: string, hintsStorageKey: string = 'HINTS'): [boolean, Dispatch<SetStateAction<boolean>>] => {
    const [hints, setHints] = useLocalStorage<HintsObjectType>(hintsStorageKey, {[hintKey]: false})

    const [hintAccepted, setHintAccepted] = useState(hints[hintKey])

    const acceptHint = () => {
        setHints({...hints, [hintKey]: true})
        setHintAccepted(true)
    }

    useEffect(() => {
        const storageValues = JSON.parse(localStorage.getItem(hintsStorageKey) as string)
        try {
            setHintAccepted(!!storageValues[hintKey])
        }
        catch (e) {
            setHintAccepted(false)
        }
    }, [JSON.stringify(hints)])

    return [hintAccepted, acceptHint]
}