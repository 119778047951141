import {FunctionComponent, MouseEvent} from "react"

import styles from "./styles.module.css"
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface FiltersProps {
    openFiltersModal: (event?: MouseEvent<HTMLButtonElement>) => void,
}

export const Filters: FunctionComponent<FiltersProps> = ({openFiltersModal}) => {
    const {t} = useTranslation()

    return (
        <div className={styles.filters}>
            <Link to="/new_inspection">
                +
            </Link>
            <GreenRoundedButton onClick={openFiltersModal}>
                {t("user:Dashboard.Filters.OpenFilterModalButton")}
            </GreenRoundedButton>
        </div>
    )
}