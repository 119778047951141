import * as Yup from "yup"

import {inspectionPhotoSchema, inspectionVideoSchema} from "@helpers/yup-extended"

export const generalDataFormValidationSchema = Yup.object().shape({
    license_plate_number: Yup.string().required("Required"),
    inspection_region: Yup.number().typeError("Required").required("Required"),
    inspection_companies: Yup.array().of(Yup.number()).min(1, "Required").typeError("Required").required("Required"),
    has_renewed_tires: Yup.boolean().test('check_for_turo', 'This option must be "yes" to continue inspection', (value, context) => {
        if (context.parent.inspection_companies.includes(3)) {
            return value
        }
        return true
    }),
    has_renewed_battery: Yup.boolean().test('check_for_turo', 'This option must be chosen to continue inspection', (value, context) => {
        if (context.parent.inspection_companies.includes(3)) {
            return value !== undefined
        }
        return true
    }),
    is_battery_voltage_high_enough: Yup.boolean().test('check_for_voltage', 'This option must be "yes" to continue inspection', (value, context) => {
        if (context.parent.inspection_companies.includes(3)) {
            if (context.parent.has_renewed_battery === false) {
                return value
            }
        }
        return true
    }),
})

export const carVerificationItemsValidationSchema = Yup.object().shape({
    registration_card: inspectionPhotoSchema.required("Required"),
    applicant_drivers_license: Yup.string(),
    odometer: inspectionPhotoSchema.required("Required"),
})

export const carInteriorFormValidationSchema = Yup.object().shape({
    horn: inspectionVideoSchema.required("Required"),
    interior_driver_side: inspectionPhotoSchema.required("Required"),
    driver_seat_adjustment: inspectionPhotoSchema.required("Required"),
    interior_passenger_side: inspectionPhotoSchema.required("Required"),
    passenger_seat_adjustment: inspectionPhotoSchema.required("Required"),
    interior_backseat: inspectionPhotoSchema.required("Required"),
})

export const carExteriorFormValidationSchema = Yup.object().shape({
    exterior_left: inspectionPhotoSchema.required("Required"),
    exterior_right: inspectionPhotoSchema.required("Required"),
    exterior_front: inspectionVideoSchema.required("Required"),
    exterior_rear: inspectionVideoSchema.required("Required"),
})

export const carTiresFormValidationSchema = Yup.object().shape({
    left_front_tire: inspectionPhotoSchema.required("Required"),
    right_front_tire: inspectionPhotoSchema.required("Required"),
    left_rear_tire: inspectionPhotoSchema.required("Required"),
    right_rear_tire: inspectionPhotoSchema.required("Required"),
})

export const checkoutFormValidationSchema = Yup.object().shape({
    terms_of_use_agreement: Yup.boolean().isTrue("You must agree with terms of use"),
    inspection_agreement: Yup.boolean().isTrue("You must agree")
})
