import {FunctionComponent} from "react"

import RegisterStep from "@assets/media/images/RegisterStep.png"
import EnterCarDataStep from "@assets/media/images/EnterCarDataStep.png"
import UploadPhotosStep from "@assets/media/images/UploadPhotosStep.png"
import PaymentStep from "@assets/media/images/PaymentStep.png"

import {Image} from "@components/common/images/Image/Image"

import ThickArrowIcon from "@assets/icons/home/ThickArrowIcon.svg"

import styles from "./styles.module.css"
import {useTranslation} from "react-i18next";

export const EasyToUseSection: FunctionComponent = () => {
    const {t} = useTranslation()

    return (
        <div className={styles.section}>
            <h1 className={styles.title}>
                {t("user:Home.EasyToUseSection.heading")}
            </h1>
            <div className={styles.content}>
                <div className={styles.stepImageContainer}>
                    <Image src={RegisterStep} alt="RegisterStep" skeletonWidth="min(562px, 70vw)" skeletonHeight="min(562px, 70vw)"/>
                </div>
                <div>
                    <Image src={ThickArrowIcon} alt="ThickArrowIcon" className={`${styles.arrow} ${styles.firstArrow}`} skeletonWidth={28}
                           skeletonHeight={26}/>
                </div>
                <div className={styles.stepImageContainer}>
                    <Image src={EnterCarDataStep} alt="EnterCarDataStep" skeletonWidth="min(562px, 70vw)" skeletonHeight="min(562px, 70vw)"/>
                </div>
                <div className={styles.desktop}/>
                <div className={styles.desktop}/>
                <div>
                    <Image src={ThickArrowIcon} alt="ThickArrowIcon" className={`${styles.arrow} ${styles.secondArrow}`} skeletonWidth={28}
                           skeletonHeight={26}/>
                </div>
                <div className={styles.reverse}>
                    <div className={styles.stepImageContainer}>
                        <Image src={PaymentStep} alt="PaymentStep" skeletonWidth="min(562px, 70vw)" skeletonHeight="min(562px, 70vw)"/>
                    </div>
                    <div>
                        <Image src={ThickArrowIcon} alt="ThickArrowIcon" className={`${styles.arrow} ${styles.thirdArrow}`} skeletonWidth={28}
                               skeletonHeight={26}/>
                    </div>
                    <div className={styles.stepImageContainer}>
                        <Image src={UploadPhotosStep} alt="UploadPhotosStep" skeletonWidth="min(562px, 70vw)" skeletonHeight="min(562px, 70vw)"/>
                    </div>
                </div>
            </div>
        </div>
    )
}
