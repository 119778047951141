import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {useInspectionCheckoutQuery} from "@features/inspection/api";
import {useTranslation} from "react-i18next";

interface CheckoutSummaryProps {
    inspection_companies: number[],
}

export const CheckoutSummary: FunctionComponent<CheckoutSummaryProps> = ({inspection_companies}) => {
    const {data: checkout} = useInspectionCheckoutQuery({inspection_companies})

    const {t} = useTranslation()

    return (
        <div className={styles.container}>
            <h4 className={styles.title}>
                {t("user:NewInspection.Checkout.CheckoutSummary.title")}
            </h4>
            <div className={styles.card}>
                <div className={styles.details}>
                    <div className={styles.detail}>
                        <p className={styles.label}>
                            {t("user:NewInspection.Checkout.CheckoutSummary.FirstInspection.label")}
                        </p>
                        <h3 className={styles.value}>
                            {checkout?.first_inspection}$
                        </h3>
                    </div>
                    <div className={styles.detail}>
                        <p className={styles.label}>
                            {t("user:NewInspection.Checkout.CheckoutSummary.EachAdditionalInspection.label")}
                        </p>
                        <h3 className={styles.value}>
                            {checkout?.each_additional_selection}$
                        </h3>
                    </div>
                </div>
                <div className={styles.total}>
                    <p className={styles.label}>
                        {t("user:NewInspection.Checkout.CheckoutSummary.TotalLabel")}
                    </p>
                    <h3 className={styles.value}>
                        {checkout?.total}$
                    </h3>
                </div>
            </div>
        </div>
    )
}