import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {Form, Formik, FormikHelpers} from "formik";
import {WhiteInput} from "@components/common/inputs/WhiteInput/WhiteInput";
import {WhiteSelectInput} from "@components/common/inputs/WhiteSelectInput/WhiteSelectInput";
import {PermittedComponent} from "@helpers/components/PermittedComponent/PermittedComponent";
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";
import {useCreateUserMutation} from "@features/users/api";
import {useTranslatedSchema} from "@helpers/hooks/useTranslatedSchema";
import {createUserValidationSchema} from "@helpers/validationSchemas/admin/update-user";
import {convertApiFormErrors, isApiFormResponse} from "@helpers/errors";
import {User} from "@appTypes/user";
import {useNavigate} from "react-router-dom";

interface Values extends Pick<User, 'email' | 'phone_number' | 'first_name' | 'last_name'> {
    role: string,
    password: string,
    password_confirmation: string,
}

const initialValues: Values = {
    email: "",
    phone_number: "+1",
    first_name: "",
    last_name: "",
    role: "user",
    password: "",
    password_confirmation: "",
}

export const CreateUser: FunctionComponent = () => {
    const navigate = useNavigate()

    const [createUser] = useCreateUserMutation()

    const schema = useTranslatedSchema(createUserValidationSchema)

    const formCreateUser = async (values: Values, {setSubmitting, setErrors}: FormikHelpers<Values>) => {
        try {
            const user = await createUser({...values}).unwrap()

            navigate(`/users/${user.id}`)
        } catch (e) {
            if (isApiFormResponse(e)) {
                const errors = convertApiFormErrors(e)

                setErrors(errors.errors)
            }
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Formik initialValues={initialValues} onSubmit={formCreateUser}
                validationSchema={schema}>
            {({
                  isSubmitting,
                  isValid,
              }) => (
                <Form className={styles.form}>
                    <div className={styles.fields}>
                        <WhiteInput name="email" label="E-mail"/>
                        <WhiteInput name="phone_number" label="Phone number"/>
                        <WhiteInput name="first_name" label="First Name"/>
                        <WhiteInput name="last_name" label="Last Name"/>
                        <WhiteSelectInput label="Role" name="role">
                            <PermittedComponent allowedRoles={["super_admin"]}>
                                <option value="super_admin" disabled>Super admin</option>
                                <option value="admin">Admin</option>
                            </PermittedComponent>
                            <option value="inspector">Inspector</option>
                            <option value="user">User</option>
                        </WhiteSelectInput>
                        <WhiteInput name="password" label="Password" type="password"/>
                        <WhiteInput name="password_confirmation" label="Password confirmation" type="password"/>
                    </div>
                    <div className={styles.actions}>
                        <GreenRoundedButton type="submit"
                                            disabled={isSubmitting || !isValid}>Save</GreenRoundedButton>
                    </div>
                </Form>
            )}
        </Formik>
    )
}