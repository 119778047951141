import React, {
    FunctionComponent,
    MouseEvent,
    ReactNode,
    useRef,
    useState
} from "react"

import styles from "./styles.module.css"
import {OverlayingPopup} from "@components/common/popups/OverlayingPopup/OverlayingPopup";
import {InspectionHintsContainer} from "@components/common/InspectionHintsContainer/InspectionHintsContainer";
import {InspectionHintCard} from "@components/common/InspectionHintCard/InspectionHintCard";
import {useHint} from "@helpers/hooks/useHint";

import ShowIcon from "@mui/icons-material/Visibility";

interface HintInterceptorProps {
    hintKey: string,
    hintTitle: string,
    hintDescription: string,
    hintFileSource: string,
    hintFileType: 'image' | 'video',
    children: ReactNode,
}

export const HintInterceptor: FunctionComponent<HintInterceptorProps> = ({
                                                                             hintKey,
                                                                             hintTitle,
                                                                             hintDescription,
                                                                             hintFileSource,
                                                                             hintFileType,
                                                                             children
                                                                         }) => {
    const [isModalOpen, setModalOpen] = useState<boolean>(false)
    const [hintAccepted, setHintAccepted] = useHint(hintKey)

    const targetElementRef = useRef<HTMLElement>()

    const interceptClick = (event: MouseEvent<HTMLDivElement>) => {
        if (hintAccepted) {
            return
        }

        event.persist()
        event.preventDefault()
        event.stopPropagation()

        targetElementRef.current = event.target as HTMLElement

        setModalOpen(true)
    }

    const onHintAccept = (event: MouseEvent<HTMLButtonElement>) => {
        if (!targetElementRef.current) {
            return onModalClose()
        }

        event.preventDefault()
        event.stopPropagation()
        event.persist()

        targetElementRef?.current?.click()

        setHintAccepted(true)
        onModalClose()
    }

    const showHint = (event: MouseEvent<SVGSVGElement>) => {
        event.persist()
        event.preventDefault()

        setModalOpen(prevState => !prevState)
    }

    const onModalClose = () => {
        setModalOpen(false)
    }

    return (
        <>
            <div onMouseDownCapture={interceptClick} className={styles.container}>
                <ShowIcon className={styles.showHintButton} onClick={showHint}/>
                {children}
            </div>
            <OverlayingPopup onClose={onModalClose} isOpened={isModalOpen}>
                <div className={styles.popup}>
                    <InspectionHintsContainer onHintAccept={onHintAccept}>
                        <InspectionHintCard title={hintTitle} description={hintDescription} fileSource={hintFileSource}
                                            fileType={hintFileType}/>
                    </InspectionHintsContainer>
                </div>
            </OverlayingPopup>
        </>
    )
}
