import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {useParams} from "react-router-dom";
import {useProposalsDetailsQuery} from "@features/proposals/api";
import {Form, Formik} from "formik";
import {WhiteInput} from "@components/common/inputs/WhiteInput/WhiteInput";
import {WhiteTextArea} from "@components/common/inputs/WhiteTextArea/WhiteTextArea";

export const ProposalDetails: FunctionComponent = () => {
    const {id} = useParams()

    const {data: proposal} = useProposalsDetailsQuery({id: id!})

    if (!proposal) {
        return null
    }

    return (
        <div className={styles.page}>
            <Formik initialValues={proposal} onSubmit={() => {}}>
                <Form className={styles.form}>
                    <WhiteInput label="Name" name="name" disabled/>
                    <WhiteInput label="E-mail" name="email" disabled/>
                    <WhiteTextArea label="Message" name="message" disabled/>
                </Form>
            </Formik>
        </div>
    )
}