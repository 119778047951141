import {ButtonHTMLAttributes, FunctionComponent, ReactNode} from "react"

import styles from "./styles.module.css"

interface BlueRoundedButtonProps {
    className?: string,
    children: ReactNode | ReactNode[],
}

export const BlueRoundedButton: FunctionComponent<BlueRoundedButtonProps & ButtonHTMLAttributes<HTMLButtonElement>> = ({className = "", children, ...buttonProps}) => {
    return (
        <button className={`${className} ${styles.button}`} {...buttonProps}>
            {children}
        </button>
    )
}