import {ButtonHTMLAttributes, forwardRef, ReactNode} from "react"

import styles from "./styles.module.css"

interface TransparentButtonProps {
    children: ReactNode,
}

export const TransparentButton = forwardRef<HTMLButtonElement, TransparentButtonProps & ButtonHTMLAttributes<HTMLButtonElement>>(({onClick=()=>{}, className="", children}, ref) => {
    return (
        <button onClick={onClick} className={`${styles.button} ${className}`} ref={ref}>
            {children}
        </button>
    )
})
