import {FunctionComponent, useState, MouseEvent} from "react"

import styles from "./styles.module.css"
import {useAdminInspectionsArchiveQuery} from "@features/inspection/api";
import {pick} from "@helpers/object";
import {DataTable} from "@components/admin/DataTable/DataTable";
import {ListPagination} from "@components/common/paginators/ListPagination/ListPagination";
import {Link, useNavigate} from "react-router-dom";
import {Inspection} from "@appTypes/inspection";
import {InspectionProgressLabel} from "@components/common/InspectionProgressLabel/InspectionProgressLabel";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {getEuropeFormattedDateTimeString} from "@helpers/date";

const displayingFields = ['id', 'status', 'user', 'inspection_region', 'inspection_country', 'date_modified', 'processed_by', 'certificates']

export const Archive: FunctionComponent = () => {
    const navigate = useNavigate()

    const [currentPage, setPage] = useState<number>(0)
    const [rowsLimit, setRowsLimit] = useState<number>(10)

    const {data: inspectionsList, isLoading} = useAdminInspectionsArchiveQuery({offset: currentPage * rowsLimit, limit: rowsLimit},
        {
            refetchOnMountOrArgChange: true
        })

    if (isLoading) {
        return null
    }

    const inspections = inspectionsList?.list?.map((inspection) => {
        const fields = pick(inspection, displayingFields) as Inspection
        return {
            id: fields.id,
            status: <InspectionProgressLabel status={fields.status}/>,
            date_modified: getEuropeFormattedDateTimeString(fields.date_modified),
            user: `${fields.user.first_name} ${fields.user.last_name}`,
            location: `${fields.inspection_region.name} (${fields.inspection_country.name})`,
            certificates: fields?.certificates ? <div className={styles.links}>
                {fields?.certificates.map((certificate, idx) => (
                    <Link key={idx} to={certificate.file} className={styles.link} download><FileDownloadIcon/> {certificate.company.name}</Link>
                ))}
            </div> : "-",
            processed_by: fields.processed_by ? `${fields.processed_by?.first_name} ${fields.processed_by?.last_name}` : "-",
        }
    }) || []
    const count = inspectionsList?.count ? inspectionsList?.count : inspections?.length

    const onRowClick = (event: MouseEvent<HTMLDivElement>, object: Inspection) => {
        event.persist()

        navigate(`/archive/${object.id}`)
    }

    return (
        <div className={styles.page}>
            <DataTable
                headings={['ID', 'Status', 'Date', 'User', 'Location', 'Certificate', 'Handler']}
                data={inspections}
                onRowClick={onRowClick}
                classNames={{
                    tableClassName: styles.table,
                    tableHeaderClassName: styles.tableHeader,
                    rowClassName: styles.row,
                    cellClassName: styles.cell,
                    headingClassName: styles.heading,
                    bodyClassName: styles.body
                }}
            />
            <ListPagination count={count} limit={rowsLimit} setLimit={setRowsLimit} currentPage={currentPage}
                            setCurrentPage={setPage}/>
        </div>
    )
}