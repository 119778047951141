import {
    CSSProperties,
    FunctionComponent,
    ImgHTMLAttributes,
    useEffect,
    useState
} from "react";
import {Skeleton} from "@mui/material";

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
    src: string,
    alt: string,
    skeletonWidth?: number | string,
    skeletonHeight?: number | string,
    skeletonStyle?: CSSProperties,
}
export const Image: FunctionComponent<ImageProps> = ({src, alt, skeletonWidth, skeletonHeight, skeletonStyle, ...imgProps}) => {
    const [image, setImage] = useState<string>()
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        fetch(src)
            .then(response => {
                response.blob().then((blob) => {
                    setImage(URL.createObjectURL(blob))
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }, [src])

    if (loading || !image) {
        return (
            <Skeleton width={skeletonWidth || "100%"} height={skeletonHeight || "auto"} variant="rectangular" style={skeletonStyle}/>
        )
    }

    return (<img src={image} alt={alt} {...imgProps}/>)
}