import {FunctionComponent, Suspense} from "react"

import styles from "./styles.module.css"


import {UserDetails as UserDetailsForm} from "@components/admin/forms/UserDetails/UserDetails";


export const UserDetails: FunctionComponent = () => {
    return (
        <div className={styles.page}>
            <div className={styles.title}>
                <h2>Account details</h2>
                <p>
                    Amet malesuada facilisis pretium tincidunt iaculis et.
                </p>
            </div>
            <Suspense fallback="Loading...">
                <UserDetailsForm/>
            </Suspense>
        </div>
    )
}
