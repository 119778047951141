import {FunctionComponent, useRef, useState} from "react"
import {Routes, Route, Link, NavLink, useParams, useNavigate} from "react-router-dom"

import styles from "./styles.module.css"
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";
import {ExpandingMenu} from "@components/common/ExpandingMenu/ExpandingMenu";
import {TransparentButton} from "@components/common/buttons/TransparentButton/TransparentButton";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import {useDispatch} from "react-redux";
import {logout} from "@features/auth/slice";
import { googleLogout } from "@react-oauth/google";
import RequestsIcon from "@mui/icons-material/DirectionsCar";
import UsersIcon from "@mui/icons-material/Person";
import ArchiveIcon from "@mui/icons-material/Archive";
import ProposalsIcon from "@mui/icons-material/Contactless";
import BlogIcon from "@mui/icons-material/Feed";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";

export const AdminHeader: FunctionComponent = () => {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const {id} = useParams()

    const anchorRef = useRef(null)
    const [isMenuOpen, setMenuOpen] = useState<boolean>(false)

    const logoutMe = () => {
        dispatch(logout())

        googleLogout()
    }

    const toggleMenu = () => {
        setMenuOpen(prevState => !prevState)
    }

    return (
        <>
            <ExpandingMenu headerContainerClassName={styles.headerContainer}>
                <ExpandingMenu.Header className={styles.header}>
                    <>
                        <Routes>
                            <Route path="requests" element={
                                <div className={styles.actions}>
                                    <h3 className={styles.title}>
                                        <RequestsIcon/> Requests
                                    </h3>
                                </div>
                            }/>
                            <Route path="requests/*" element={
                                <div className={styles.actions}>
                                    <h3 className={styles.title}>
                                        <RequestsIcon/> Request #{`${id}`}
                                    </h3>
                                </div>
                            }/>
                            <Route path="archive" element={
                                <div className={styles.actions}>
                                    <h3 className={styles.title}>
                                        <ArchiveIcon/> Archive
                                    </h3>
                                </div>
                            }/>
                            <Route path="archive/*" element={
                                <div className={styles.actions}>
                                    <h3 className={styles.title}>
                                        <ArchiveIcon/> Archived Request #{`${id}`}
                                    </h3>
                                </div>
                            }/>
                            <Route path="users" element={
                                <div className={styles.actions}>
                                    <h3 className={styles.title}>
                                        <UsersIcon/> Users
                                    </h3>
                                    <GreenRoundedButton className={styles.addButton} onClick={() => navigate("/users/create")}>
                                        +
                                    </GreenRoundedButton>
                                </div>
                            }/>
                            <Route path="users/*" element={
                                <div className={styles.actions}>
                                    <h3 className={styles.title}>
                                        <UsersIcon/> User{id && ` #${id}`}
                                    </h3>
                                    <GreenRoundedButton className={styles.addButton} onClick={() => navigate("/users/create")}>
                                        +
                                    </GreenRoundedButton>
                                </div>
                            }/>
                            <Route path="proposals" element={
                                <div className={styles.actions}>
                                    <h3 className={styles.title}>
                                        <ProposalsIcon/> Proposals
                                    </h3>
                                </div>
                            }/>
                            <Route path="proposals/*" element={
                                <div className={styles.actions}>
                                    <h3 className={styles.title}>
                                        <ProposalsIcon/> Proposal #{`${id}`}
                                    </h3>
                                </div>
                            }/>
                            <Route path="blog" element={
                                <div className={styles.actions}>
                                    <h3 className={styles.title}>
                                        <BlogIcon/> Blog
                                    </h3>
                                    <GreenRoundedButton className={styles.addButton}>
                                        +
                                    </GreenRoundedButton>
                                </div>
                            }/>
                            <Route path="settings" element={
                                <div className={styles.actions}>
                                    <h3 className={styles.title}>
                                        <SettingsIcon/> Settings
                                    </h3>
                                </div>
                            }/>
                            <Route path="*" element={null}/>
                        </Routes>
                    </>
                    <TransparentButton onClick={toggleMenu} className={styles.burgerButton}>
                        {isMenuOpen ? <CloseIcon/> : <MenuIcon/>}
                    </TransparentButton>
                </ExpandingMenu.Header>
                <ExpandingMenu.Mobile open={isMenuOpen} onClose={toggleMenu} anchor={anchorRef.current} className={styles.mobile}>
                    <div className={styles.mobileMenu} onClick={toggleMenu}>
                        <NavLink to="requests" className={({isActive}) => isActive ? styles.active : ""}>
                            <RequestsIcon/> Requests
                        </NavLink>
                        <NavLink to="users" className={({isActive}) => isActive ? styles.active : ""}>
                            <UsersIcon/> Users
                        </NavLink>
                        <NavLink to="archive" className={({isActive}) => isActive ? styles.active : ""}>
                            <ArchiveIcon/> Archive
                        </NavLink>
                        <NavLink to="proposals" className={({isActive}) => isActive ? styles.active : ""}>
                            <ProposalsIcon/> Proposals
                        </NavLink>
                        {/*<NavLink to="blog" className={({isActive}) => isActive ? styles.active : ""}>*/}
                        {/*    <BlogIcon/> Blog*/}
                        {/*</NavLink>*/}
                        <NavLink to="settings" className={({isActive}) => isActive ? styles.active : ""}>
                            <SettingsIcon/> Settings
                        </NavLink>
                        <NavLink to="#" onClick={logoutMe}>
                            <LogoutIcon/> Logout
                        </NavLink>
                    </div>
                </ExpandingMenu.Mobile>
            </ExpandingMenu>
            <div ref={anchorRef}/>
        </>
    )
}