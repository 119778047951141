import {api} from "@features/redux/api";
import {InspectionPaymentStatus} from "@appTypes/payment";

type getInspectionPaymentLinkQueryResult = {
    url: string,
    status: InspectionPaymentStatus,
}
type getInspectionPaymentLinkQueryArgs = {
    id: number,
}

export const paymentsApi = api.injectEndpoints({
    endpoints: builder => ({
        getInspectionPayment: builder.query<getInspectionPaymentLinkQueryResult, getInspectionPaymentLinkQueryArgs>({
                query: ({id}) => ({
                    url: `payments/inspection/${id}`,
                    method: "GET",
                }),
            }
        ),
    })
})

export const {
    useGetInspectionPaymentQuery
} = paymentsApi
