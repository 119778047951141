export const clearFormData = (key: string) => {
    localStorage.removeItem(key)
}

export const getFormData = (key: string) => {
    const rawFormData = localStorage.getItem(key);

    if (!rawFormData) {
        return null;
    }

    return JSON.parse(rawFormData);
}

export const setFormData = (key: string, data: any) => {
    localStorage.setItem(key, JSON.stringify(data));
}

export const getFormValues = (formDataKey: string, ...keys: string[]) => {
    const data = getFormData(formDataKey)
    if (data){
        return Object.entries(data).reduce((acc: {[key: string]: any}, [key, value]) => {
            if (keys.includes(key)) {
                acc[key] = value;
            }
            return acc;
        }, {})
    }
    return {}
}
