import {ChangeEvent, FunctionComponent, useState, MouseEvent} from "react"

import styles from "./styles.module.css"
import {WhiteSearchInput} from "@components/common/inputs/WhiteSearchInput/WhiteSearchInput";
import {useUsersListQuery} from "@features/users/api";
import {DataTable} from "@components/admin/DataTable/DataTable";
import {pick} from "@helpers/object";
import {ListPagination} from "@components/common/paginators/ListPagination/ListPagination";
import {User} from "@appTypes/user";
import {useNavigate} from "react-router-dom";

const displayingFields = ['id', 'email', 'phone_number', 'first_name', 'last_name', 'is_active']

export const Users: FunctionComponent = () => {
    const navigate = useNavigate()
    const [searchValue, setSearchValue] = useState<string>("")
    const [currentPage, setPage] = useState<number>(0)
    const [rowsLimit, setRowsLimit] = useState<number>(10)

    const {data: usersList, isLoading} = useUsersListQuery({offset: currentPage * rowsLimit, limit: rowsLimit, search: searchValue},
        {
            refetchOnMountOrArgChange: true
        })

    const onSearchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.persist()
        event.preventDefault()

        setSearchValue(event.target.value)
    }

    if (isLoading) {
        return null
    }

    const onRowClick = (event: MouseEvent<HTMLDivElement>, user: User) => {
        navigate(`/users/${user.id}`)
    }

    const users = usersList?.list?.map((user) => pick(user, displayingFields)) || []

    const count = usersList?.count ? usersList?.count : users?.length

    return (
        <div className={styles.page}>
            <WhiteSearchInput value={searchValue} onChange={onSearchValueChange}/>
            <DataTable
                headings={['ID', 'E-mail', 'Phone number', 'First name', 'Last name', 'Active']}
                data={users}
                onRowClick={onRowClick}
                classNames={{
                    tableClassName: styles.table,
                    tableHeaderClassName: styles.tableHeader,
                    rowClassName: styles.row,
                    cellClassName: styles.cell,
                    headingClassName: styles.heading,
                    bodyClassName: styles.body
                }}
            />
            <ListPagination count={count} limit={rowsLimit} setLimit={setRowsLimit} currentPage={currentPage}
                            setCurrentPage={setPage}/>
        </div>
    )
}