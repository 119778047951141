import {ChangeEvent, ChangeEventHandler, FunctionComponent, ReactElement, useId} from "react"

import styles from "./styles.module.css"
import {useField, FieldHookConfig} from "formik";

interface GreenRadioButtonProps {
    label: string | ReactElement,
    onChange?: ChangeEventHandler,
    className?: string,
}

export const GreenRadioButton: FunctionComponent<FieldHookConfig<string> & GreenRadioButtonProps> = ({label, onChange,  className = "", value, ...fieldProps}) => {
    const [field, meta, helpers] = useField(fieldProps)

    const id = useId()

    const innerOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.persist()

        helpers.setValue(value)
    }

    return (
        <div className={styles.container}>
            <input {...field} id={id} type="radio" onChange={innerOnChange} className={`${styles.input} ${meta.touched && meta.error ? styles.error : ""}`} checked={field.value === value}/>
            <label htmlFor={id} className={styles.label}>{label}</label>
        </div>
    )
}
