import {ChangeEvent, Dispatch, FunctionComponent, SetStateAction, useMemo, useState} from "react"

import styles from "./styles.module.css"
import {OverlayingPopup, OverlayingPopupProps} from "@components/common/popups/OverlayingPopup/OverlayingPopup";
import {FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";
import {Form, Formik, FormikHelpers} from "formik";
import {WhiteCheckbox} from "@components/common/checkboxes/WhiteCheckbox/WhiteCheckbox";
import {InspectionsListFilters} from "@features/inspection/api";
import {InspectionStatus} from "@appTypes/inspection";
import {GreenRadioButton} from "@components/common/radioButtons/GreenRadioButton/GreenRadioButton";
import {useTranslation} from "react-i18next";

interface InspectionsListFiltersPopup extends Omit<OverlayingPopupProps, 'children'> {
    filters: InspectionsListFilters,
    setFilters: Dispatch<SetStateAction<InspectionsListFilters>>,
}

type initialFilters = {
    [key in InspectionStatus]?: boolean
} & {ordering: InspectionsListFilters["ordering"]}

export const InspectionsListFiltersPopup: FunctionComponent<InspectionsListFiltersPopup> = ({
                                                                                                isOpened,
                                                                                                onClose,
                                                                                                filters,
                                                                                                setFilters
                                                                                            }) => {
    const {t} = useTranslation()

    const initialValues: initialFilters = useMemo(() => {
        return {
            submitted: false,
            pending: false,
            reviewed: false,
            rejected: false,
            unpaid: false,
            ...Object.fromEntries((filters.status__in.map((status) => [status, true]))),
            ordering: filters.ordering,
        }
    }, [filters])

    const onSubmit = (filters: initialFilters, {}: FormikHelpers<initialFilters>) => {
        setFilters({
            ordering: filters.ordering,
            status__in: Object.keys(filters).filter(key => filters[key as keyof initialFilters]) as InspectionStatus[],
        })

        onClose()
    }

    return (
        <OverlayingPopup isOpened={isOpened} onClose={onClose}>
            <div className={styles.contents}>
                <div>
                    <Formik onSubmit={onSubmit} initialValues={initialValues}>
                        <Form>
                            <p className={styles.label}>{t("user:Dashboard.Filters.ByDate.label")}</p>
                            <div className={styles.radioGroup}>
                                <GreenRadioButton name="ordering" value="-date_created" label={t("user:Dashboard.Filters.ByDate.Newest") as string}/>
                                <GreenRadioButton name="ordering" value="date_created" label={t("user:Dashboard.Filters.ByDate.Oldest") as string}/>
                            </div>
                            <p className={styles.label}>{t("user:Dashboard.Filters.ByStatus.label")}</p>
                            <div className={styles.checkboxGroup}>
                                <WhiteCheckbox name="submitted" label={t("user:Dashboard.Filters.ByStatus.Submitted") as string}/>
                                <WhiteCheckbox name="pending" label={t("user:Dashboard.Filters.ByStatus.Pending") as string}/>
                                <WhiteCheckbox name="reviewed" label={t("user:Dashboard.Filters.ByStatus.Reviewed") as string}/>
                                <WhiteCheckbox name="rejected" label={t("user:Dashboard.Filters.ByStatus.Rejected") as string}/>
                                <WhiteCheckbox name="unpaid" label={t("user:Dashboard.Filters.ByStatus.Unpaid") as string}/>
                            </div>
                            <div className={styles.submitButton}>
                                <GreenRoundedButton type="submit">
                                    {t("user:Dashboard.Filters.SubmitButton")}
                                </GreenRoundedButton>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </OverlayingPopup>
    )
}