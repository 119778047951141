import {FunctionComponent, Suspense, useRef, useState} from "react"

import {Link} from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Logo from "@assets/icons/BlackLogo.svg"

import styles from "./styles.module.css"
import {ExpandingMenu} from "@components/common/ExpandingMenu/ExpandingMenu";
import {TransparentButton} from "@components/common/buttons/TransparentButton/TransparentButton";
import {LanguageSwitcher} from "@components/common/LanguageSwitcher/LanguageSwitcher";
import {PermittedComponent} from "@helpers/components/PermittedComponent/PermittedComponent";
import {GuestLinks, UserLinks} from "@components/headers/UserHeader/links";

export const UserHeader: FunctionComponent = () => {
    const [isMenuOpen, setMenuOpen] = useState(false)

    const anchorRef = useRef<HTMLDivElement>(null)

    const toggleMenu = () => {
        setMenuOpen(prevState => !prevState)
    }

    return (
        <>
            <ExpandingMenu headerContainerClassName={styles.headerContainer}>
                <ExpandingMenu.Header className={styles.header}>
                    <Suspense fallback={"loading..."}>
                        <LanguageSwitcher/>
                    </Suspense>
                    <Link to="/" className={styles.logo}>
                        <img src={Logo} alt={Logo}/>
                    </Link>
                    <TransparentButton onClick={toggleMenu}>
                        {isMenuOpen ? <CloseIcon/> : <MenuIcon/>}
                    </TransparentButton>
                </ExpandingMenu.Header>
                <ExpandingMenu.Mobile open={isMenuOpen} onClose={toggleMenu} anchor={anchorRef.current} className={styles.mobile}>
                    <div className={styles.mobileMenu} onClick={toggleMenu}>
                        <PermittedComponent allowedRoles={[null]}>
                            <GuestLinks/>
                        </PermittedComponent>
                        <PermittedComponent allowedRoles={["user"]}>
                            <UserLinks/>
                        </PermittedComponent>
                    </div>
                </ExpandingMenu.Mobile>
                <ExpandingMenu.Desktop open={isMenuOpen} onClose={toggleMenu} containerClassName={styles.desktop}>
                    <div className={styles.desktopMenu} onClick={toggleMenu}>
                        <PermittedComponent allowedRoles={[null]}>
                            <GuestLinks/>
                        </PermittedComponent>
                        <PermittedComponent allowedRoles={["user"]}>
                            <UserLinks/>
                        </PermittedComponent>
                    </div>
                </ExpandingMenu.Desktop>
            </ExpandingMenu>
            <div ref={anchorRef}/>
        </>
    )
}