import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Form, Formik, FormikHelpers} from "formik";
import {getFormData, getFormValues, setFormData} from "@helpers/multiStepForm";
import {NEW_INSPECTION_FORM_DATA_KEY} from "@helpers/constants/inspection";
import {
    carExteriorFormValidationSchema
} from "@helpers/validationSchemas/new-inspection";
import {InspectionPhoto, InspectionVideo} from "@appTypes/files";
import {HintInterceptor} from "@helpers/components/HintInterceptor/HintInterceptor";
import {WhiteCameraImageInput} from "@components/common/inputs/WhiteCameraImageInput/WhiteCameraImageInput";

import ExteriorLeftHint from "@assets/media/hints/ExteriorLeftHint.jpg"
import ExteriorRightHint from "@assets/media/hints/ExteriorRightHint.jpg"
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";
import {WhiteCameraVideoInput} from "@components/common/inputs/WhiteCameraVideoInput/WhiteCameraVideoInput";

const ExteriorFrontHint = require("@assets/media/hints/ExteriorFrontHint.mp4")
const ExteriorRearHint = require("@assets/media/hints/ExteriorRearHint.mp4")

interface CarExteriorProps {
    title?: string,
    description?: string,
}

interface Values {
    exterior_left: InspectionPhoto | null,
    exterior_right: InspectionPhoto | null,
    exterior_front: InspectionVideo | null,
    exterior_rear: InspectionVideo | null,
}

export const CarExterior: FunctionComponent<CarExteriorProps> = () => {
    const initialValues: Values = {
        exterior_front: null,
        exterior_left: null,
        exterior_rear: null,
        exterior_right: null,
        ...getFormValues(NEW_INSPECTION_FORM_DATA_KEY, 'exterior_front', 'exterior_left', 'exterior_rear', 'exterior_right'),
    }

    const navigate = useNavigate()
    const location = useLocation()

    const {t} = useTranslation()

    const onSubmit = async (values: Values, {setSubmitting, setErrors}: FormikHelpers<Values>) => {
        setFormData(NEW_INSPECTION_FORM_DATA_KEY, {
            ...getFormData(NEW_INSPECTION_FORM_DATA_KEY),
            ...values
        })

        navigate(location, {
            state: {
                activeStep: 4
            }
        })

        setSubmitting(false)
    }

    return (
        <div className={styles.step}>
            <Formik initialValues={initialValues} onSubmit={onSubmit}
                    validationSchema={carExteriorFormValidationSchema}>
                {({
                      isSubmitting,
                      errors,
                  }) => (
                    <Form className={styles.form}>
                        <HintInterceptor
                            hintKey="exterior_left"
                            hintTitle={t("user:NewInspection.CarExterior.hints.ExteriorLeft.title")}
                            hintDescription={t("user:NewInspection.CarExterior.hints.ExteriorLeft.description")}
                            hintFileSource={ExteriorLeftHint}
                            hintFileType="image">
                            <WhiteCameraImageInput name="exterior_left"
                                                   label={t("user:NewInspection.CarExterior.ExteriorLeftImageInput.label")}/>
                        </HintInterceptor>
                        <HintInterceptor
                            hintKey="exterior_right"
                            hintTitle={t("user:NewInspection.CarExterior.hints.ExteriorRight.title")}
                            hintDescription={t("user:NewInspection.CarExterior.hints.ExteriorRight.description")}
                            hintFileSource={ExteriorRightHint}
                            hintFileType="image">
                            <WhiteCameraImageInput name="exterior_right"
                                                   label={t("user:NewInspection.CarExterior.ExteriorRightImageInput.label")}/>
                        </HintInterceptor>
                        <HintInterceptor
                            hintKey="exterior_front"
                            hintTitle={t("user:NewInspection.CarExterior.hints.ExteriorFront.title")}
                            hintDescription={t("user:NewInspection.CarExterior.hints.ExteriorFront.description")}
                            hintFileSource={ExteriorFrontHint}
                            hintFileType="video">
                            <WhiteCameraVideoInput name="exterior_front"
                                                   label={t("user:NewInspection.CarExterior.ExteriorFrontVideoInput.label")}/>
                        </HintInterceptor>
                        <HintInterceptor
                            hintKey="exterior_rear"
                            hintTitle={t("user:NewInspection.CarExterior.hints.ExteriorRear.title")}
                            hintDescription={t("user:NewInspection.CarExterior.hints.ExteriorRear.description")}
                            hintFileSource={ExteriorRearHint}
                            hintFileType="video">
                            <WhiteCameraVideoInput name="exterior_rear"
                                                   label={t("user:NewInspection.CarExterior.ExteriorRearVideoInput.label")}/>
                        </HintInterceptor>
                        <div className={styles.submitButton}>
                            <GreenRoundedButton type="submit" disabled={isSubmitting || !!Object.values(errors).length}>
                                {t("common:Inspection.NextStepButton")}
                            </GreenRoundedButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}