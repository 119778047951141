import {FC, useCallback, MouseEvent, useState} from "react";
import { Portal } from "@helpers/components/Portal/Portal";
import { GreenButton } from "@components/common/buttons/GreenButton/GreenButton";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.css";

const CookiesAlert: FC = () => {
    const { t } = useTranslation();
    const [shown, setShown] = useState<boolean>(!!parseInt(localStorage.getItem("cookiesAlertAccepted") ?? "0"));

    const acceptCookies = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        localStorage.setItem("cookiesAlertAccepted", "1");
        setShown(true);
    }, []);

    if (shown) {
        return null;
    }

    return (
        <Portal>
            <div className={styles.alert}>
                <h3 className={styles.title}>
                    {t("common:cookiesAlert.title")}
                </h3>
                <p className={styles.body}>
                    {t("common:cookiesAlert.body")}
                </p>

                <div className={styles.acceptButtons}>
                    <GreenButton onClick={acceptCookies}>
                        {t("common:cookiesAlert.accept")}
                    </GreenButton>
                    <GreenButton onClick={acceptCookies}>
                        {t("common:cookiesAlert.acceptAll")}
                    </GreenButton>
                </div>
            </div>
        </Portal>
    );
}

export {
    CookiesAlert,
}
