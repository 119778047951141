import {FunctionComponent, ReactNode, RefObject, useId, useRef} from "react"

import {ClickAwayListener, Popper, PopperPlacementType} from "@mui/base"

import styles from "./styles.module.css"

interface PopoverProps {
    onClose: any,
    open: boolean,
    anchorElementRef: RefObject<HTMLElement>,
    popperClassName?: string,
    placement?: PopperPlacementType,
    children: ReactNode,
}

export const Popover: FunctionComponent<PopoverProps> = ({ onClose, open, anchorElementRef, placement = "bottom", popperClassName = "string", children }) => {
    const id = useId()

    if(!open) {
        return null
    }

    return (
        <ClickAwayListener onClickAway={onClose}>
                <Popper
                    id={id}
                    open={open}
                    anchorEl={anchorElementRef.current}
                    className={popperClassName}
                    placement={placement}
                >
                    {children}
                </Popper>
        </ClickAwayListener>
    );
};