import {FunctionComponent, useMemo, useState} from "react"

import CircleIcon from "@mui/icons-material/Circle";

import styles from "./styles.module.css"
import {Skeleton} from "@mui/material";

interface InspectionHintCardProps {
    title: string,
    description: string,
    fileSource: string,
    fileType: 'image' | 'video',
}

export const InspectionHintCard: FunctionComponent<InspectionHintCardProps> = ({title, description, fileSource, fileType}) => {
    const [contentLoaded, setContentLoaded] = useState<boolean>(false);

    const file = useMemo(() => {
        switch (fileType) {
            case "image":
                return (<img src={fileSource} alt="" onLoad={() => setContentLoaded(true)}/>);
            case "video":
                return (<video src={fileSource} loop muted controls autoPlay controlsList="nodownload" playsInline onCanPlay={() => setContentLoaded(true)}/>);
        }
    }, [fileSource]);

    return (
        <div className={styles.hint}>
            <p className={styles.title}>
                {title}
            </p>
            <div className={styles.descriptionContainer}>
                <CircleIcon fontSize="inherit" className={styles.circlePointer}/>
                <p>{description}</p>
            </div>
            <div className={styles.attachment}>
                <div style={{display: contentLoaded ? "contents" : "none"}}>
                    {file}
                </div>
                {!contentLoaded && <Skeleton style={{aspectRatio: "1 / 1", width: "100%"}} />}
            </div>
        </div>
    )
}