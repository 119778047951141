import { api } from "@features/redux/api";
import {Country, InspectionRegion, City} from "@appTypes/inspection";

export type countriesFilters = void;

export type countriesListQueryResponse = Country[];

const regionsApi = api.injectEndpoints({
    endpoints: builder => ({
        regionsList: builder.query<InspectionRegion[], { country?: number }>({
            query: ({ country }) => ({
                url: `/companies/countries/regions?country=${country}`,
                method: "GET"
            })
        }),
        citiesList: builder.query<City[], { region?: number }>({
            query: ({ region }) => ({
                url: `/companies/countries/regions/cities?region=${region}`,
                method: "GET",
            })
        }),
        countriesList: builder.query<countriesListQueryResponse, countriesFilters>({
            query: (args) => ({
                url: "/companies/countries/list",
                method: "GET",
                params: args,
            })
        }),
    })
})

export const {
    useRegionsListQuery,
    useCountriesListQuery,
    useCitiesListQuery,
} = regionsApi
