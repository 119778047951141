import {FunctionComponent} from "react"

import TelegramIcon from "@assets/icons/social/TelegramIcon.svg";
import InstagramIcon from "@assets/icons/social/InstagramIcon.svg";
import FacebookIcon from "@assets/icons/social/FacebookIcon.svg";

import styles from "./styles.module.css"
import {
    AccessTime,
    MailOutline,
    PhoneSharp,
} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const ContactsCard: FunctionComponent = () => {
    const {t} = useTranslation();

    return (
        <div className={styles.card}>
            <h3>{t("user:ContactUs.card.title")}</h3>

            <div className={styles.items}>
                <div className={styles.item}>
                    <MailOutline/> <Link to="mailto:support@insve.com">support@insve.com</Link>
                </div>
                <div className={styles.item}>
                    <PhoneSharp/> <Link to="tel:(808) 800-92-92">(808) 800-92-92</Link>
                </div>
                <div className={styles.item}>
                    <AccessTime/> <p>09:00 am - 09:00 pm</p>
                </div>
            </div>
            <div className={styles.socials}>
                <p>{t("user:ContactUs.card.socials.heading")}</p>
                <div className={styles.icons}>
                    {/*<a href={t("common:socials.WhatsApp")?.toString()}>*/}
                    {/*    <img src={WhatsAppIcon} alt={"WhatsAppIcon"}/>*/}
                    {/*</a>*/}
                    <a href={t("common:socials.Telegram")?.toString()}>
                        <img src={TelegramIcon} alt={"TelegramIcon"}/>
                    </a>
                    <a href={t("common:socials.Instagram")?.toString()}>
                        <img src={InstagramIcon} alt={"InstagramIcon"}/>
                    </a>
                    <a href={t("common:socials.Facebook")?.toString()}>
                        <img src={FacebookIcon} alt={"FacebookIcon"}/>
                    </a>
                </div>
            </div>
        </div>
    )
}