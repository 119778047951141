import {FC, ReactNode, useState, MouseEvent, useRef} from "react";

import Lightbox, { Slide } from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

import "yet-another-react-lightbox/styles.css";
import styles from "./styles.module.css";

interface ImageLightboxProps {
    slides?: Slide[];
    children: ReactNode | ReactNode[];
}

export const ImageLightbox: FC<ImageLightboxProps> = ({ slides, children }) => {
    const [open, setOpen] = useState<boolean>(false);

    const zoomRef = useRef(null);

    const openLightBox = (event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.persist();
        event.stopPropagation();

        setOpen(true);
    }

    return (
        <>
            <div onClick={openLightBox}>
                { children }
            </div>
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={slides}
                plugins={[Zoom]}
                zoom={{ ref: zoomRef, maxZoomPixelRatio: 10}}
            />
        </>
    );
}

