import {FunctionComponent, MouseEvent, ReactNode, useEffect, useRef, useState} from "react"

import styles from "./styles.module.css"
import {OverlayingPopup} from "@components/common/popups/OverlayingPopup/OverlayingPopup";

import FlipIcon from '@mui/icons-material/Flip';
import RotateCounterClockWise from '@mui/icons-material/RotateLeft';
import RotateClockWise from '@mui/icons-material/RotateRight';

interface LightBoxProps {
    children: ReactNode | ReactNode[],
}

interface Settings {
    scaleX: number,
    scaleY: number,
    rotate: number,
}

export const LightBox: FunctionComponent<LightBoxProps> = ({children}) => {
    const [isOpen, setOpen] = useState<boolean>(false)

    const [containerSize, setContainerSize] = useState<{width: string | number, height: string | number}>({width: '100%', height: 'auto'})
    const containerInitialSize = useRef({width: 0, height: 0})

    const containerRef = useRef<HTMLDivElement>(null)

    const [settings, modifySettings] = useState<Settings>({
        scaleX: 1,
        scaleY: 1,
        rotate: 0,
    })

    useEffect(() => {
        if (containerRef.current && containerInitialSize.current) {
            switch (settings.rotate) {
                case 90:
                    setContainerSize({width: containerInitialSize.current.height, height: containerInitialSize.current.width})
                    break
                case 180:
                    setContainerSize({width: containerInitialSize.current.height, height: containerInitialSize.current.width})
                    break
                case -90:
                    setContainerSize({width: containerInitialSize.current.height, height: containerInitialSize.current.width})
                    break
                case -180:
                    setContainerSize({width: containerInitialSize.current.height, height: containerInitialSize.current.width})
                    break
                default:
                    setContainerSize({width: containerInitialSize.current.width, height: containerInitialSize.current.height})
            }
        }
    }, [settings.rotate]);

    const openLightBox = (event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault()
        event.persist()
        event.stopPropagation()

        setOpen(true)
    }

    const onClose = () => {
        setOpen(false)
    }

    const flipVertically = () => {
        modifySettings({...settings, scaleY: -settings.scaleY})
    }

    const flipHorizontally = () => {
        modifySettings({...settings, scaleX: -settings.scaleX})
    }

    const rotateClockWise = () => {
        modifySettings({...settings, rotate: (settings.rotate + 90) % 360})
    }

    const rotateCounterClockWise = () => {
        modifySettings({...settings, rotate: (settings.rotate - 90) % 360})
    }

    return (
        <>
            <div onClick={openLightBox}>
                {children}
            </div>
            <OverlayingPopup isOpened={isOpen} onClose={onClose}>
                <>
                        <div className={styles.wrapper}>
                            <div ref={(el) => {
                                    if (!el) {
                                        return;
                                    }
                                    containerInitialSize.current = {
                                        width: el?.offsetWidth || 0,
                                        height: el?.offsetHeight || 0,
                                    }
                            }} className={styles.content} style={{
                                transform: `
                scaleX(${settings.scaleX}) 
                scaleY(${settings.scaleY}) 
                rotate(${settings.rotate}deg)
                `,
                                height: containerSize.height,
                                width: containerSize.width,
                            }}>
                                {children}
                            </div>
                        </div>
                    <div className={styles.controls}>
                        <FlipIcon onClick={flipHorizontally}/>
                        <FlipIcon style={{transform: 'rotate(90deg)'}} onClick={flipVertically}/>
                        <RotateCounterClockWise onClick={rotateCounterClockWise}/>
                        <RotateClockWise onClick={rotateClockWise}/>
                    </div>
                </>
            </OverlayingPopup>
        </>
    )
}
